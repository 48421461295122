import { useEffect, useState } from "react";
import { Button, Form, OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';
import { config } from './Config/Config';
import { Tooltip as ReactTooltip } from "react-tooltip";
import { decrypt, encrypt } from "./tool";

const feedbackIcon = `${config.host}/images/feedback-icon.svg`
const ratingIcon = `${config.host}/images/rating-popup-icon.svg`
const rating1 = `${config.host}/images/rating1.svg`;
const rating2 = `${config.host}/images/rating2.svg`;
const rating3 = `${config.host}/images/rating3.svg`;
const rating4 = `${config.host}/images/rating4.svg`;
const rating5 = `${config.host}/images/rating5.svg`;
const orating1 = `${config.host}/images/fill-rating1.svg`;
const orating2 = `${config.host}/images/fill-rating2.svg`;
const orating3 = `${config.host}/images/fill-rating3.svg`;
const orating4 = `${config.host}/images/fill-rating4.svg`;
const orating5 = `${config.host}/images/fill-rating5.svg`;
const grating1 = `${config.host}/images/rating1-gray-outlined.svg`;
const grating2 = `${config.host}/images/rating2-gray-outlined.svg`;
const grating3 = `${config.host}/images/rating3-gray-outlined.svg`;
const grating4 = `${config.host}/images/rating4-gray-outlined.svg`;
const grating5 = `${config.host}/images/rating5-gray-outlined.svg`;

const Review = ({ user, limit, placeholder, productDetail, setActive, isAnonymous, ratingAccess, validateReviewAdded }) => {
    const [feedbackForm, setFeedbackForm] = useState('');
    const [questions, setQuestions] = useState([]);
    const [section, setSection] = useState("confirmation");
    const [skipped, setSkipped] = useState(false)
    const [ratingType, setRatingType] = useState('')
    const [addRating, setAddRating] = useState(false);
    const [rating, setRating] = useState("")
    const [ratingComment, setRatingComment] = useState("");
    const [selected1, setSelected1] = useState(false);
    const [selected2, setSelected2] = useState(false);
    const [selected3, setSelected3] = useState(false);
    const [selected4, setSelected4] = useState(false);
    const [selected5, setSelected5] = useState(false);
    const [feedbackRating, setFeedbackRating] = useState(0);
    const [genericRating, setGenericRating] = useState(0);
    const [responses, setResponses] = useState([]);
    const [currentPage, setCurrentPage] = useState(1)
    const [currentQuest, setCurrentQuest] = useState(null);
    const [success, setSuccess] = useState(false)

    useEffect(() => {
        getFeedbackForm();
        return () => {
            cleanUp();
        };
    }, [])
    const handleSubmit = async () => {
        let ip = await fetch('https://api.db-ip.com/v2/free/self');
        if (ip) {
            ip = await ip.json();
        }
        var tokenId = encrypt({ "companyId": productDetail.companyId, "productId": productDetail.productId, userIP: ip.ipAddress })
        let headers;
        if (user && !isAnonymous) {
            headers = {
                'Accept': 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('feddup__user')),
                'Content-Type': 'application/json'
            }
        } else {
            headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        let userRes = await fetch(config.userUrl + `user/createProductFeedbackByUser`, {
            method: "POST",
            headers,
            body: JSON.stringify({
                "tokenId": tokenId,
                "versionId": productDetail.id,
                "responses": JSON.stringify(responses),
                formId: feedbackForm?.id
            })
        }).then(res => res.json()).then(() => { setSuccess(true); validateReviewAdded() }).catch(e => null);

    }
    const getFeedbackForm = async () => {
        let userRes = await fetch(config.userUrl + `user/getCurrentFeedbackFormAnonymous`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "productId": productDetail.productId,
                "versionId": productDetail.id
            })
        }).then(res => res.json())
            .then(res => {
                if (res.status === true) {
                    setFeedbackForm(res.feedbackForm[0])
                    setQuestions(res.feedbackForm[0]?.questions || []);
                    setResponses(res.feedbackForm[0]?.questions || []);
                    setCurrentQuest(res.feedbackForm[0]?.questions[0][1] || { type: 'generic' })
                } else {
                    console.log("error")
                }
            })
            .catch(e => null);
    }

    const cleanUp = () => {
        setAddRating(false);
        setSelected1(false);
        setSelected2(false);
        setSelected3(false);
        setSelected4(false);
        setSelected5(false);
        setRating('');
        setRatingComment("");
        setSection("confirmation")
        setRatingType("");
        setSkipped(false);
        setCurrentPage(1);
        setFeedbackRating(0);
        setGenericRating(0);
        setResponses([]);
    }
    const handleBackClick = () => {
        if (currentPage === 1) return;
        setCurrentPage(currentPage - 1)
        if (questions.hasOwnProperty(currentPage - 2)) {
            setCurrentPage(currentPage - 1);
            setCurrentQuest(questions[currentPage - 2][currentPage - 1])
        }
        else {
            setCurrentQuest(null)
        }
    }
    const handleForwardClick = () => {
        if (currentPage === 4) return;
        setCurrentPage(currentPage + 1);
        if (questions.hasOwnProperty(currentPage)) {
            setCurrentQuest(questions[currentPage][currentPage + 1])
        }
        else {
            setCurrentQuest(null)
        }
    }
    const handleRatingSubmit = async () => {
        // if (ratingComment?.length > 0 && ratingComment?.length <= 120) {
        var tokenId = encrypt({ "companyId": productDetail.companyId, "productId": productDetail.productId })
        let headers;
        if (user && !isAnonymous) {
            headers = {
                'Accept': 'application/json',
                "Authorization": "Bearer " + decrypt(localStorage.getItem('feddup__user')),
                'Content-Type': 'application/json'
            }
        } else {
            headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        let userRes = await fetch(config.userUrl + `user/addRatingCommentForProductByUser`, {
            method: "POST",
            headers,
            body: JSON.stringify({
                "tokenId": tokenId,
                "rating": rating,
                "comment": ratingComment,
                "versionId": productDetail.id
            })
        }).then(res => res.json()).then(() => { setSuccess(true); validateReviewAdded() }).catch(e => null);

        // }
        // else alert("Review must be between 1-120 characters")
    }
    const handleFeedbackRating = (val1, val2) => {
        if ((currentQuest["scoringDirection"] === "Ascending" && currentQuest['resposne'] === val1) || (currentQuest["scoringDirection"] === "Descending" && currentQuest['resposne'] === val2)) {
            setFeedbackRating(0)
            return;
        }
        if (currentQuest["scoringDirection"] === "Ascending") {
            setFeedbackRating(val1);
            responses[currentPage - 1][currentPage]["resposne"] = val1;
        }
        else {
            setFeedbackRating(val2);
            responses[currentPage - 1][currentPage]["resposne"] = val2;
        }
    }
    const handleGenericRating = (val) => {
        if (genericRating === val) {
            setGenericRating(0)
            return;
        }
        setGenericRating(val);
        let currentResponses = [...responses];
        currentResponses[currentPage - 1][currentPage]["response"] = { ...currentResponses[currentPage - 1][currentPage]["response"], "rating": val };
        setResponses(currentResponses)

    }
    function commentRating1(e, val) {
        setSelected1(true)
        setSelected2(false)
        setSelected3(false)
        setSelected4(false)
        setSelected5(false)
        setRating(val);
    }
    function commentRating2(e, val) {
        setSelected1(false)
        setSelected2(true)
        setSelected3(false)
        setSelected4(false)
        setSelected5(false)
        setRating(val);
    }
    function commentRating3(e, val) {
        setSelected1(false)
        setSelected2(false)
        setSelected3(true)
        setSelected4(false)
        setSelected5(false)
        setRating(val);
    }
    function commentRating4(e, val) {
        setSelected1(false)
        setSelected2(false)
        setSelected3(false)
        setSelected4(true)
        setSelected5(false)
        setRating(val);
    }
    function commentRating5(e, val) {
        setSelected1(false)
        setSelected2(false)
        setSelected3(false)
        setSelected4(false)
        setSelected5(true)
        setRating(val);
    }
    const getMaxLength = (wordLimit) => {
        if (!wordLimit) return;
        if (wordLimit === "Max 50 words") return "50";
        else if (wordLimit === "50 - 100 words") return "100";
        else if (wordLimit === "Max 100 words") return "100";
        else return "150";

    }
    const getMinLength = (wordLimit) => {
        if (!wordLimit) return;
        if (wordLimit === "Max 50 words") return "0";
        else if (wordLimit === "50 - 100 words") return "50";
        else if (wordLimit === "Max 100 words") return "0";
        else return "100";

    }
    const minLength = getMinLength(currentQuest?.wordLimit)
    const maxLength = getMaxLength(currentQuest?.wordLimit)

    if (success) {
        return (
            <div className="flex flex-col items-center justify-center min-h-[300px]">
                <svg width="101" height="101" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1848_8649)">
                        <path d="M50.6139 100.919C78.0712 100.919 100.33 78.5334 100.33 50.9192C100.33 23.305 78.0712 0.919189 50.6139 0.919189C23.1565 0.919189 0.897949 23.305 0.897949 50.9192C0.897949 78.5334 23.1565 100.919 50.6139 100.919Z" fill="#E6570E" />
                        <path d="M23.9802 53.5977L45.287 75.0266L77.2471 26.812" stroke="#FFB858" stroke-width="10" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                        <clipPath id="clip0_1848_8649">
                            <rect width="100" height="100" fill="white" transform="translate(0.548828 0.919189)" />
                        </clipPath>
                    </defs>
                </svg>
                <h1 className="text-center mt-4 font-medium" style={{ color: '#222' }}>Thanks for posting</h1>
            </div>
        )
    }
    return (
        <>
            {
                limit.limit ?
                    <>
                        <div className="feddup__popup__rating__topbar">
                            <p className="text-center" style={{ color: '#222' }}>{limit.message}</p>
                            {!user && <button style={{ border: "none", outline: "none" }}><span>Sign Up</span></button>}
                        </div>
                    </>
                    :
                    <>
                        <div>
                            {section === "confirmation" ?
                                <div>
                                    <p className="feddup__popup__rating__review__confirmationText" style={{ color: '#222' }}>Would you like to</p>
                                </div>
                                : section === "rating" ?
                                    <div>
                                        <p className="feddup__popup__rating__review__ratingText" style={{ color: '#222' }}>Overall Rating for this version</p>
                                    </div>
                                    : section === "feedback" && skipped ?
                                        <div className='feddup__popup__rating__review__feedbackText__div'>
                                            <p className='feddup__popup__rating__review__feedbackText' style={{ color: '#222' }}>Rate your overall Experience for this version</p>
                                        </div>
                                        : null
                            }
                        </div>
                        <div>
                            {section === "confirmation" ?
                                <div className='feddup__popup__rating__review__confirmation__container'>
                                    <div className="feddup__popup__rating__review__confirmation__container__options">
                                        {(productDetail?.planName == "Basic" || !questions?.length) ? "" : <div className="feddup__popup__rating__review__confirmation__container__options">
                                            <div className={`feddup__popup__rating__review__confirmation__container__option`} onClick={() => { if (questions?.length) { setRatingType("feedback"); setSection("feedback") } else setSection('rating') }}>
                                                <p className='feddup__popup__rating__review__confirmation__container__option__title' style={{ color: '#222' }}>Fill Feedback Form</p>
                                                <p className='feddup__popup__rating__review__confirmation__container__option__description' style={{ color: '#222' }}>Quick 4 questions to help the creators improve this product more.</p>
                                                <img src={feedbackIcon} alt='' className='feddup__popup__rating__review__confirmation__container__option__image' />
                                            </div>
                                        </div>}
                                        <div className={`feddup__popup__rating__review__confirmation__container__option relative`} onClick={() => { if (ratingAccess) { setRatingType("rating"); setSection("rating") } }}>
                                            <p className='feddup__popup__rating__review__confirmation__container__option__title' style={{ color: '#222' }}>Rate Only</p>
                                            <p className='feddup__popup__rating__review__confirmation__container__option__description' style={{ color: '#222' }}>Rate the application and provide additional thoughts.</p>
                                            <img src={ratingIcon} alt='' className='feddup__popup__rating__review__confirmation__container__option__image' />
                                            {ratingAccess ? <></> : <div className="absolute inset-0 bg-black bg-opacity-50 rounded-md grid place-items-center">
                                                <h1 className="text-xl font-medium text-yellow-400">Already Provided</h1>
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="feddup__popup__rating__review__confirmation__container__actions">
                                        <div className="feddup__popup__rating__review__confirmation__container__action__cancel"
                                            onClick={() => { cleanUp(); setActive('') }}
                                        >Cancel</div>
                                        {/* <div className='bg-orange-400 w-[100px] h-8 border-2 border-[#FFB858] rounded-[10px] rotate-6 inset-0'>
                                            <button onClick={() => {
                                                if (ratingType === "rating") setSection("rating")
                                                else setSection("feedback")
                                            }}
                                                className="bg-orange-600 text-white border-orange-600 text-base rounded-[10px] w-[95px] h-8 font-medium leading-tight -rotate-6"
                                            >
                                                Continue
                                            </button>
                                        </div> */}
                                    </div>
                                </div>
                                :
                                 section === "rating" ?
                                    <div className="feddup__popup__rating__review__rating__container">
                                        <Form>
                                            <div className="feddup__popup__rating__review__rating__container__ratings">
                                                <div className="feddup__popup__rating__review__rating__container__rating">
                                                    <img src={selected1 ? orating1 : rating ? grating1 : rating1} onClick={(e, i) => commentRating1(e, 1)} className={`feddup__popup__rating__review__rating__container__rating__image ${selected1 ? 'opacity-100' : 'opacity-20'}`} />
                                                    <span className={`feddup__popup__rating__review__rating__container__rating__span ${selected1 ? "" : "hidden"}`} style={{ color: '#222' }}>Fed Up!</span>
                                                </div>
                                                <div className="feddup__popup__rating__review__rating__container__rating">
                                                    <img src={selected2 ? orating2 : rating ? grating2 : rating2} onClick={(e, i) => commentRating2(e, 2)} className={`feddup__popup__rating__review__rating__container__rating__image ${selected2 ? 'opacity-100' : 'opacity-20'}`} />
                                                    <span className={`feddup__popup__rating__review__rating__container__rating__span ${selected2 ? "" : "hidden"}`} style={{ color: '#222' }}>Hate it!</span>
                                                </div>
                                                <div className="feddup__popup__rating__review__rating__container__rating">
                                                    <img src={selected3 ? orating3 : rating ? grating3 : rating3} onClick={(e, i) => commentRating3(e, 3)} className={`feddup__popup__rating__review__rating__container__rating__image ${selected3 ? 'opacity-100' : 'opacity-20'}`} />
                                                    <span className={`feddup__popup__rating__review__rating__container__rating__span ${selected3 ? "" : "hidden"}`} style={{ color: '#222' }}>It's Ok!</span>
                                                </div>
                                                <div className="feddup__popup__rating__review__rating__container__rating">
                                                    <img src={selected4 ? orating4 : rating ? grating4 : rating4} onClick={(e, i) => commentRating4(e, 4)} className={`feddup__popup__rating__review__rating__container__rating__image ${selected4 ? 'opacity-100' : 'opacity-20'}`} />
                                                    <span className={`feddup__popup__rating__review__rating__container__rating__span ${selected4 ? "" : "hidden"}`} style={{ color: '#222' }}>Like it!</span>
                                                </div>
                                                <div className="feddup__popup__rating__review__rating__container__rating">
                                                    <img src={selected5 ? orating5 : rating ? grating5 : rating5} onClick={(e, i) => commentRating5(e, 5)} className={`feddup__popup__rating__review__rating__container__rating__image ${selected5 ? 'opacity-100' : 'opacity-20'} `} />
                                                    <span className={`feddup__popup__rating__review__rating__container__rating__span  ${selected5 ? "" : "hidden"}`} style={{ color: '#222' }}>Love it!</span>
                                                </div>
                                            </div><br />
                                            <textarea
                                                className="feddup__popup__rating__review__rating__container__rating__textarea"
                                                id="exampleFormControlTextarea1"
                                                style={{ fontFamily: "poppins", resize: "none", width: '100%', color:'#222' }}
                                                rows="5"
                                                maxLength={150}
                                                name="ratingComment"
                                                value={ratingComment}
                                                placeholder="Let us know your thoughts"
                                                onChange={(e) => setRatingComment(e.target.value)}
                                            />
                                            <div style={{ fontFamily: "poppins", fontSize: "12px" , color:'grey'}}>{ratingComment.length}/150 characters</div>
                                        </Form>
                                        {/* <div className="mt-3 suggestion-box-text" style={{ float: "left" }}>Post guidelines <span style={{ color: "#E6570E" }}>read here.</span></div> */}
                                        <br />
                                        <div className="feddup__popup__rating__review__confirmation__container__actions">
                                            <div className="feddup__popup__rating__review__confirmation__container__action__cancel"
                                                onClick={cleanUp}
                                            >Cancel</div>
                                            {(rating.length != 0) ?
                                                <div className='feddup__popup__rating__review__rating__container__rating__submitDiv'>
                                                    <button onClick={handleRatingSubmit}
                                                        className="feddup__popup__rating__review__rating__container__rating__submitBtn"
                                                    >
                                                        Submit
                                                    </button>
                                                </div> :
                                                <div className='feddup__popup__rating__review__rating__container__rating__submitDiv opacity-50'>
                                                    <button id="submit-button"
                                                        disabled={true}
                                                        className="disabled feddup__popup__rating__review__rating__container__rating__submitBtn opacity-95"
                                                    >
                                                        Submit
                                                    </button>
                                                    <ReactTooltip
                                                        anchorSelect="#submit-buttton"
                                                        place="bottom"
                                                        content="Please add Rating and Description"
                                                        style={{ color: '#222' }}
                                                    />
                                                </div>}
                                        </div>
                                    </div>
                                    :
                                     section === "feedback" ?
                                        <div className='feddup__popup__rating__review__feedback__container'>
                                            <Row className='feddup__popup__rating__review__feedback__container__row'>
                                                {currentQuest ?
                                                    <Col md={11} className="feddup__popup__rating__review__feedback__container__col">
                                                        <p className='text-xs text-gray-300' style={{ color: '#999' }}>Question {currentPage}</p>
                                                        <p className='text-sm mt-[2px] text-black font-normal'>{currentQuest["question"]}<span style={{ color: 'red' }} className={currentQuest["required"] ? `text-red-600 text-xl` : "hidden"}>*</span></p>
                                                        {currentQuest && currentQuest.type === "mcq" ?
                                                            <div key={currentPage - 1 + "mcq"} className='bg-white py-3 rounded-xl mb-4'>

                                                                <form className='flex flex-col gap-2 mt-1'>
                                                                    {currentQuest.options.map((option, index) => {
                                                                        return (
                                                                            <label className='flex gap-3' key={index + "mcqoptions"} style={{ color: '#222' }}>
                                                                                <input type="checkbox" name={currentQuest["question"]} value={option}
                                                                                    onChange={(e) => {
                                                                                        // let currentResponses = [...responses];
                                                                                        // currentResponses[currentPage - 1][currentPage]["response"] = e.target.value;
                                                                                        let currentResponses = [...responses];

                                                                                        let arr = currentResponses[currentPage - 1][currentPage]["response"]?.toString()
                                                                                        arr = arr?.split(",")
                                                                                        if (Array.isArray(arr)) {
                                                                                            if (arr.includes(e.target.value?.toString())) {
                                                                                                arr = arr.filter(item => item != e.target.value?.toString())
                                                                                                currentResponses[currentPage - 1][currentPage]["response"] = `${arr.join(",")}`;
                                                                                            }
                                                                                            else {
                                                                                                arr.push(e.target.value?.toString());
                                                                                                currentResponses[currentPage - 1][currentPage]["response"] = `${arr.join(",")}`;
                                                                                            }
                                                                                        }
                                                                                        else {
                                                                                            currentResponses[currentPage - 1][currentPage]["response"] = e.target.value?.toString();
                                                                                        }

                                                                                        setResponses(currentResponses);
                                                                                    }}
                                                                                    checked={responses[currentPage - 1][currentPage]["response"]?.split(",").includes(option?.toString())}
                                                                                />
                                                                                {option}
                                                                            </label>
                                                                        )
                                                                    })}
                                                                </form>
                                                            </div>
                                                            :
                                                            currentQuest && currentQuest.type === "singlechoice" ?
                                                                <div key={currentPage - 1 + "singlechoice"} className='bg-white py-3 rounded-xl mb-4'>
                                                                    <form className='flex flex-col gap-2 mt-1'>
                                                                        {currentQuest.options.map((option, index) => {
                                                                            return (
                                                                                <label className='flex gap-3' key={index + "singleoptions"} style={{ color: '#222' }}>
                                                                                    <input type="radio" name={currentQuest["question"]} value={option}
                                                                                        onChange={(e) => {
                                                                                            let currentResponses = [...responses];
                                                                                            currentResponses[currentPage - 1][currentPage]["response"] = e.target.value;
                                                                                            setResponses(currentResponses)
                                                                                        }}
                                                                                        checked={responses[currentPage - 1][currentPage]["response"] === option}
                                                                                    />
                                                                                    {option}
                                                                                </label>
                                                                            )
                                                                        })}
                                                                    </form>
                                                                </div>
                                                                :
                                                                currentQuest && currentQuest.type === "rating" ?
                                                                    <div key={currentPage - 1 + "rating"} className='bg-white rounded-xl mb-4'>
                                                                        {currentQuest["scaleType"] === "Yes-No" ?
                                                                            <div className='flex flex-col w-full justify-center gap-8 my-4'>
                                                                                <div className='flex items-center gap-2 cursor-pointer'>
                                                                                    <span className={`w-4 h-4 rounded-full border ${responses[currentPage - 1][currentPage]["response"] === 'Yes' ? 'bg-orange-600' : ""}`}
                                                                                        onClick={() => {
                                                                                            let currentResponses = [...responses];
                                                                                            currentResponses[currentPage - 1][currentPage]["response"] = 'Yes'
                                                                                            setResponses(currentResponses);
                                                                                        }}></span>
                                                                                    <p style={{ color: '#222' }}>Yes</p>
                                                                                </div>
                                                                                <div className='flex items-center gap-2'>
                                                                                    <span className={`w-4 h-4 rounded-full border ${responses[currentPage - 1][currentPage]["response"] === 'Uncertain' ? 'bg-orange-600' : ""}`}
                                                                                        onClick={() => {
                                                                                            let currentResponses = [...responses];
                                                                                            currentResponses[currentPage - 1][currentPage]["response"] = 'Uncertain'
                                                                                            setResponses(currentResponses);
                                                                                        }}></span>
                                                                                    <p style={{ color: '#222' }}>Uncertain</p>
                                                                                </div>
                                                                                <div className='flex items-center gap-2'>
                                                                                    <span className={`w-4 h-4 rounded-full border ${responses[currentPage - 1][currentPage]["response"] === 'No' ? 'bg-orange-600' : ""}`}
                                                                                        onClick={() => {
                                                                                            let currentResponses = [...responses];
                                                                                            currentResponses[currentPage - 1][currentPage]["response"] = 'No'
                                                                                            setResponses(currentResponses);
                                                                                        }}></span>
                                                                                    <p style={{ color: '#222' }}>No</p>
                                                                                </div>

                                                                            </div> :
                                                                            <div className='flex flex-col my-4 gap-3'>
                                                                                <div className='flex gap-2 items-center'>
                                                                                    <img src={currentQuest["scoringDirection"] === "Ascending" ? rating1 : rating5} alt=''
                                                                                        className={`${(currentQuest["scoringDirection"] === "Ascending" && currentQuest['resposne'] === 1) || (currentQuest["scoringDirection"] === "Descending" && currentQuest['resposne'] === 5) ? "bg-orange-600" : ""} w-10 cursor-pointer`}
                                                                                        onClick={() => handleFeedbackRating(1, 5)} />
                                                                                    <p className="text-xs text-center" style={{ color: '#222' }}>{currentQuest["scoringDirection"] === "Ascending" ? currentQuest["ratingDesc"][0] : currentQuest["ratingDesc"][4]}</p>
                                                                                </div>
                                                                                <div className='flex gap-2 items-center'>
                                                                                    <img src={currentQuest["scoringDirection"] === "Ascending" ? rating2 : rating4} alt=''
                                                                                        className={`${(currentQuest["scoringDirection"] === "Ascending" && currentQuest['resposne'] === 2) || (currentQuest["scoringDirection"] === "Descending" && currentQuest['resposne'] === 4) ? "bg-orange-600" : ""} w-10 cursor-pointer`}
                                                                                        onClick={() => handleFeedbackRating(2, 4)} />
                                                                                    <p className="text-xs text-center" style={{ color: '#222' }}>{currentQuest["scoringDirection"] === "Ascending" ? currentQuest["ratingDesc"][1] : currentQuest["ratingDesc"][3]}</p>
                                                                                </div>
                                                                                <div className='flex gap-2 items-center'>
                                                                                    <img src={rating3} alt=''
                                                                                        className={`${currentQuest['resposne'] === 3 ? "bg-orange-600" : ""} w-10 cursor-pointer`}
                                                                                        onClick={() => handleFeedbackRating(3, 3)} />
                                                                                    <p className="text-xs text-center" style={{ color: '#222' }}>{currentQuest["ratingDesc"][2]}</p>
                                                                                </div>
                                                                                <div className='flex gap-2 items-center'>
                                                                                    <img src={currentQuest["scoringDirection"] === "Ascending" ? rating4 : rating2} alt=''
                                                                                        className={`${(currentQuest["scoringDirection"] === "Ascending" && currentQuest['resposne'] === 4) || (currentQuest["scoringDirection"] === "Descending" && currentQuest['resposne'] === 2) ? "bg-orange-600" : ""} w-10 cursor-pointer`}
                                                                                        onClick={() => handleFeedbackRating(4, 2)} />
                                                                                    <p className="text-xs text-center" style={{ color: '#222' }}>{currentQuest["scoringDirection"] === "Ascending" ? currentQuest["ratingDesc"][3] : currentQuest["ratingDesc"][1]}</p>
                                                                                </div>
                                                                                <div className='flex gap-2 items-center'>
                                                                                    <img src={currentQuest["scoringDirection"] === "Ascending" ? rating5 : rating1} alt=''
                                                                                        className={`${(currentQuest["scoringDirection"] === "Ascending" && currentQuest['resposne'] === 5) || (currentQuest["scoringDirection"] === "Descending" && currentQuest['resposne'] === 1) ? "bg-orange-600" : ""} w-10 cursor-pointer`}
                                                                                        onClick={() => handleFeedbackRating(5, 1)} />
                                                                                    <p className="text-xs text-center" style={{ color: '#222' }}>{currentQuest["scoringDirection"] === "Ascending" ? currentQuest["ratingDesc"][4] : currentQuest["ratingDesc"][0]}</p>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    :
                                                                    currentQuest && currentQuest.type === "textfield" ?
                                                                        <div key={currentPage - 1 + "textfield"} className='bg-white rounded-xl mb-4'>
                                                                            <div className='border rounded-lg w-full flex flex-col gap-2 my-4'>
                                                                                <textarea
                                                                                    className="p-2 min-h-[7rem] resize-none w-full focus:outline-none bg-white"
                                                                                    placeholder={currentQuest.placeholder || "Let us know more"}
                                                                                    onChange={(e) => {
                                                                                        let currentResponses = [...responses];
                                                                                        currentResponses[currentPage - 1][currentPage]["response"] = e.target.value;
                                                                                        setResponses(currentResponses);
                                                                                    }}
                                                                                    minLength={minLength}
                                                                                    maxLength={maxLength}
                                                                                    value={responses[currentPage - 1][currentPage]["response"]?.details}
                                                                                    style={{ color: '#222' }}
                                                                                />
                                                                                <div className='flex justify-end p-2'>
                                                                                    <p className='text-gray-400' style={{ color: 'grey' }}>{currentQuest["wordLimit"]}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        : currentQuest && currentQuest.type === "generic" ?
                                                                            <div key={currentPage - 1 + "textfield"} className='bg-white rounded-xl mb-4'>
                                                                                <p className='my-3 text-black text-sm' style={{ color: '#222' }}>Rate your overall Experience for this version</p>
                                                                                <div className="feddup__popup__rating__review__rating__container__ratings">
                                                                                    <div className="feddup__popup__rating__review__rating__container__rating">
                                                                                        <img src={genericRating == 1 ? orating1 : rating1} onClick={(e, i) => handleGenericRating(1)} className={`feddup__popup__rating__review__rating__container__rating__image ${genericRating == 1 ? 'opacity-100' : 'opacity-20'}`} />
                                                                                        <span style={{ color: '#222' }} className={`feddup__popup__rating__review__rating__container__rating__span ${genericRating == 1 ? "" : "hidden"}`}>Fed Up!</span>
                                                                                    </div>
                                                                                    <div className="feddup__popup__rating__review__rating__container__rating">
                                                                                        <img src={genericRating == 2 ? orating2 : rating2} onClick={(e, i) => handleGenericRating(2)} className={`feddup__popup__rating__review__rating__container__rating__image ${genericRating == 2 ? 'opacity-100' : 'opacity-20'}`} />
                                                                                        <span style={{ color: '#222' }} className={`feddup__popup__rating__review__rating__container__rating__span ${genericRating == 2 ? "" : "hidden"}`}>Hate it!</span>
                                                                                    </div>
                                                                                    <div className="feddup__popup__rating__review__rating__container__rating">
                                                                                        <img src={genericRating == 3 ? orating3 : rating3} onClick={(e, i) => handleGenericRating(3)} className={`feddup__popup__rating__review__rating__container__rating__image ${genericRating == 3 ? 'opacity-100' : 'opacity-20'}`} />
                                                                                        <span style={{ color: '#222' }} className={`feddup__popup__rating__review__rating__container__rating__span ${genericRating == 3 ? "" : "hidden"}`}>It's Ok!</span>
                                                                                    </div>
                                                                                    <div className="feddup__popup__rating__review__rating__container__rating">
                                                                                        <img src={genericRating == 4 ? orating4 : rating4} onClick={(e, i) => handleGenericRating(4)} className={`feddup__popup__rating__review__rating__container__rating__image ${genericRating == 4 ? 'opacity-100' : 'opacity-20'}`} />
                                                                                        <span style={{ color: '#222' }} className={`feddup__popup__rating__review__rating__container__rating__span ${genericRating == 4 ? "" : "hidden"}`}>Like it!</span>
                                                                                    </div>
                                                                                    <div className="feddup__popup__rating__review__rating__container__rating">
                                                                                        <img src={genericRating == 5 ? orating5 : rating5} onClick={(e, i) => handleGenericRating(5)} className={`feddup__popup__rating__review__rating__container__rating__image ${genericRating == 5 ? 'opacity-100' : 'opacity-20'} `} />
                                                                                        <span style={{ color: '#222' }} className={`feddup__popup__rating__review__rating__container__rating__span  ${genericRating == 5 ? "" : "hidden"}`}>Love it!</span>
                                                                                    </div>
                                                                                </div>
                                                                                <textarea
                                                                                    className="border border-gray-400 rounded p-2 min-h-[8rem] mt-4 mb-12 resize-none w-full border-gray focus:outline-none placeholder:text-gray-500 focus:text-black text-black"
                                                                                    placeholder="Additional Thoughts"
                                                                                    style={{ color: '#222' }}
                                                                                    value={responses[currentPage - 1][currentPage]["resposne"]?.["details"]}
                                                                                    onChange={(e) => {
                                                                                        let currentResponses = [...responses];
                                                                                        currentResponses[currentPage - 1][currentPage]["response"] = { ...responses[currentPage - 1][currentPage]["response"], "details": e.target.value };
                                                                                        setResponses(currentResponses)
                                                                                    }}
                                                                                />
                                                                            </div> :

                                                                            <></>}
                                                    </Col> : <></>}
                                            </Row>

                                            <div className="feddup__popup__rating__review__confirmation__container__actions">
                                                <div className="feddup__popup__rating__review__confirmation__container__action__cancel"
                                                    onClick={() => {
                                                        if (currentPage === 1) {
                                                            cleanUp()
                                                            setActive('');
                                                        } else handleBackClick();
                                                    }}
                                                >Back</div>
                                                <div className='feddup__popup__rating__review__confirmation__container__action__skip'>
                                                    {currentPage != questions?.length ? <p className={`${currentQuest["required"] ? "hidden" : ""}`}
                                                        onClick={() => {
                                                            responses[currentPage - 1][currentPage]["resposne"] = null;
                                                            if (currentPage === 4)
                                                                handleSubmit();
                                                            else
                                                                handleForwardClick();
                                                        }}
                                                    >Skip </p> : <></>}
                                                    <div className="relative group">
                                                        <button onClick={() => {
                                                            if (!(responses[currentPage - 1][currentPage].response || responses[currentPage - 1][currentPage].resposne)) return
                                                            if (currentPage === 4 || skipped)
                                                                handleSubmit();
                                                            else
                                                                handleForwardClick();
                                                        }}
                                                            className={` feddup__popup__rating__review__rating__container__rating__next ${(responses[currentPage - 1][currentPage].response || responses[currentPage - 1][currentPage].resposne) ? "bg-orange-600" : "feddup-grey-bg"}`}
                                                        >{currentPage === 4 || skipped ? "Submit" : "Next"}
                                                        </button>
                                                        {(responses[currentPage - 1][currentPage].response || responses[currentPage - 1][currentPage].resposne) ?
                                                            "" :
                                                            <div className="feddup__popup__rating__review__rating__container__someText" style={{ zIndex: "1003" }}>
                                                                <p className="text-xs text-gray-500" style={{ color: 'gray' }}>Please provide your response</p>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : <>
                                        </>
                            }
                        </div>

                    </>}
        </>
    )
}

export default Review