import { useEffect, useState } from "react";
import Popup from "./Popup";
import { config } from "./Config/Config";
import jwt_decode from 'jwt-decode';
import { decrypt } from "./tool";


function App() {

  const [show, setShow] = useState(false);
  const [productDetail, setProductDetail] = useState()

  const validateUser = async (data) => {
    let user = {}
    if (localStorage.getItem("feddup__user")) {
      let token = decrypt(localStorage.getItem("feddup__user"));
      user = jwt_decode(token)
    }


    let res = await fetch(`${config.userUrl}admin/validateProduct`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "productId": data,
        userId: user?.userInfo?.userId
      })
    }).then(res => res.json()).catch(e => null);

    if (res.status && res.product) {
      setProductDetail(res.product)
      setShow(true);
    }
    else {
      console.error("Invalid Feddup Script, kindly paste the correct script")
    }
  }

  useEffect(() => {
    let data = document.getElementById('#feddup12345')?.innerHTML;
    // if (data) {
      //   data = data.split(" ").pop();
      //   validateUser(data.slice(1, data.length - 2))
      // }
      if (data) {
      let dataId = data.split("\"");
      if (dataId[dataId.length - 1].length > 12) {
        dataId = dataId[dataId.length - 1];
      }
      else if (dataId[dataId.length - 2].length > 12) {
        dataId = dataId[dataId.length - 2];
      }
      else throw Error("Invalid Script");
      validateUser(dataId)
    } else {
      console.error("Invalid Feddup Script, kindly paste the correct script")
    }
  }, [])

  return (
    <div style={{ fontFamily: `'Poppins', sans-serif !important` }}>
      {show ? <Popup productDetail={productDetail} /> : <></>}
    </div>
  );
}

export default App;

