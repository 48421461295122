import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { config } from "./Config/Config";
// import imageIcon from './images/image-icon.svg';
const imageIcon = `${config.host}/images/image-icon.svg`

const fileTypes = ["JPG", "PNG"];

function AddImage({ editById, imageUrl, onChangeImage }) {
    const [file, setFile] = useState(null);
    const [image, setImage] = useState("");
    const [showBox, setShowBox] = useState(true)
    const handleChange = async (file) => {
        setShowBox(false);
        setFile(file);
        console.log("file", file)
        if (file) {
            const res = await uploadImage(file);
            if (res) {
                setImage(res)
            }
            onChangeImage(res)
        }
    };
    const deleteImage = () => {
        setFile(null);
        setImage("");
        setShowBox(true);
    }
    const uploadImage = async (image) => {
        let data = new FormData()
        data.append("file", image)
        data.append("upload_preset", "feddup")
        data.append("cloud_name", "feddupnew")
        const resp = await fetch(`https://api.cloudinary.com/v1_1/feddupnew/image/upload`, {
            method: "post",
            body: data
        })
        let res = await resp.json();
        return res.secure_url
    }

    return (
        <>{showBox ?
            <FileUploader
                multiple={false}
                handleChange={handleChange} // function to get uploaded files
                name="file"
                children={
                    <div className="drag-drop">
                        <div className="i-flex">
                            {/* <img src={imageIcon} style={{ marginLeft: "5px", marginTop: "-5px", width: "20px" }} /> */}
                            <svg color="grey" width="28" height="28" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M35.1666 6.33203V9.9987H29.6666V15.4987H25.9999V9.9987H20.4999V6.33203H25.9999V0.832031H29.6666V6.33203H35.1666ZM19.5833 19.1654C20.3128 19.1651 21.0124 18.8751 21.5282 18.359C22.0439 17.8429 22.3335 17.1431 22.3333 16.4135C22.333 15.6839 22.0429 14.9843 21.5269 14.4686C21.0108 13.9529 20.311 13.6633 19.5814 13.6635C19.2202 13.6637 18.8625 13.7349 18.5288 13.8733C18.195 14.0116 17.8919 14.2144 17.6365 14.4699C17.3811 14.7254 17.1786 15.0288 17.0405 15.3626C16.9023 15.6964 16.8313 16.0541 16.8314 16.4154C16.8315 16.7766 16.9028 17.1343 17.0412 17.468C17.1795 17.8017 17.3823 18.1049 17.6378 18.3603C17.8933 18.6156 18.1966 18.8182 18.5305 18.9563C18.8643 19.0944 19.222 19.1655 19.5833 19.1654ZM25.9999 25.0944L25.0594 24.0494C24.7155 23.6665 24.2949 23.3603 23.8249 23.1506C23.3549 22.941 22.8461 22.8327 22.3314 22.8327C21.8168 22.8327 21.3079 22.941 20.8379 23.1506C20.3679 23.3603 19.9473 23.6665 19.6034 24.0494L18.4026 25.3877L9.49992 15.4987L3.99992 21.6092V9.9987H16.8333V6.33203H3.99992C3.02746 6.33203 2.09483 6.71834 1.40719 7.40597C0.71956 8.09361 0.333252 9.02624 0.333252 9.9987V31.9987C0.333252 32.9712 0.71956 33.9038 1.40719 34.5914C2.09483 35.2791 3.02746 35.6654 3.99992 35.6654H25.9999C26.9724 35.6654 27.905 35.2791 28.5926 34.5914C29.2803 33.9038 29.6666 32.9712 29.6666 31.9987V19.1654H25.9999V25.0944Z" fill="#AAAAAA" />
                            </svg>
                            <div className="ml-2 b-image text-center"><span className="fontStyle whitespace-nowrap" style={{ color: 'grey' }}>Choose a file or drag and drop here</span></div>
                        </div>
                        <div style={{ fontSize: "10px", color: "grey", float: "right" }}>Only JPG's,PNG's are allowed</div>
                    </div>}
                types={["JPG", "PNG", 'JPEG', 'jpg', 'png', 'jpeg']}
            /> : ""}
            <div className="mb-2"></div>
            <>{file ? <div className="drag-drop flex justify-center"> <div className="mt-3" style={{ fontSize: "16px", color: 'grey' }}>{file?.name.length > 15 ? `${file?.name.substring(0, 15)}...` : file.name}
                <svg onClick={deleteImage} style={{ cursor: "pointer", color: 'red', display: 'inline-block', stroke: 'red' }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                </svg>
            </div></div> :
                ""
            }</>
            <div className="mb-2"></div>
        </>

    );
}

export default AddImage;
{/* <>{file ? <div><img src={image} style={{ width: "200px" }} /><br /><span style={{ marginLeft: "23%", cursor: "pointer" }} onClick={deleteImage}><i class="fa fa-trash-o" style={{ cursor: "pointer" }} aria-hidden="true"></i></span></div> :
<>
    {imageUrl!=null ? <div><img src={imageUrl} style={{ width: "150px", height: "100px" }} /><br /><span style={{ marginLeft: "23%", cursor: "pointer" }} onClick={() => editFeedbackById("img")}><i class="fa fa-trash-o" style={{ cursor: "pointer" }} aria-hidden="true"></i></span></div> : ""}
</>
}</> */}