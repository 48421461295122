import React, { useState } from 'react';
import { config } from './Config/Config';
import { encrypt } from './tool';

function Login({ isLogin, setIsLogin, setUser, getButtons, setStatus }) {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    })
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState("")

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        let userRes = await fetch(config.apiUrl + `auth/login`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "email": formData.email,
                "password": encrypt(formData.password)
            })
        }).then(res => res.json())
            .then(res => {
                const msg = res.message.charAt(0).toUpperCase() + res.message.slice(1)
                if (res.status === true) {
                    let tk  = encrypt(res.accessToken)
                    localStorage.setItem("feddup__user", tk)
                    setLoading(false)
                    setSuccess(true)
                    getButtons(res)
                    setUser(res)
                    setStatus(tk)
                } else {
                    setLoading(false)
                    setSuccess(false)
                    console.log("e", res.message)
                    setMsg(msg)
                }
            })
            .catch(e => console.log("e", e));
    }
    if (!isLogin) return <></>
    return (
        <>
            <div class="feddup__popup__login" style={{ zIndex: 50003 }}>
                <div className='bg-transparent fixed inset-0' onClick={() => setIsLogin(false)}>

                </div>
                <div className='feddup__popup__login__container relative'>
                    <div className='feddup__popup__login__nav'>
                        {logo}
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" onClick={() => setIsLogin(false)} className="close">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                    {loading ?
                        <Loading />
                        :
                        success ?
                            <Success onClick={() => { setIsLogin(false); setSuccess(false); window.location.reload() }} />
                            : <form onSubmit={handleSubmit} className='feddup__popup__login__form'>
                                <h1 style={{ color: '#222' }} className='feddup__popup__login__form__heading'>Hello there!<br />Login to Feddup!</h1>
                                <input style={{ color: '#222' }} minLength={6} className='feddup__popup__login__form__input' placeholder='Email' type="email" name="email" id="email" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                                <input style={{ color: '#222' }} minLength={6} className='feddup__popup__login__form__input' placeholder='Password' type="password" name="password" id="password" value={formData.password} onChange={(e) => setFormData({ ...formData, password: e.target.value })} />
                                <div style={{ color: 'red' }} className="error">{msg}</div>
                                <a href={config.webUrl + "forgotpassword"} target="_blank" className='feddup__popup__login__form__forget' rel="noreferrer">Forgot Password?</a>

                                {/* <button type="submit" onSubmit={handleSubmit} className='py-1.5 px-8 rounded-md bg-orange-600 text-white font-medium text-lg w-full my-8'>Login</button> */}
                                <div className='feddup__popup__login__form__submit__container'>
                                    <button type="submit" onSubmit={handleSubmit}
                                        className="submit"
                                        style={{ color: 'white' }}
                                    >
                                        Login
                                    </button>
                                </div>
                                <p style={{ color: '#222' }}>Don't have an account? <a href={config.webUrl + "signup"} target="_blank" className='feddup__popup__login__form__register'>Register here</a></p>
                            </form>}
                </div>
            </div>
        </>
    )
}

export default Login

const Loading = () => {
    return <div className='feddup__popup__login__loading__container'>
        <div className='feddup__popup__login__loading__container__loader'></div>
    </div>
}
const Success = ({ onClick }) => {
    return <div className='feddup__popup__login__success__container'>
        <svg width="120" viewBox="0 0 182 182" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M57.667 95.167L82.667 120.167L124.334 70.167" stroke="#FF8B66" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M91.0003 174.334C137.024 174.334 174.334 137.024 174.334 91.0003C174.334 44.9766 137.024 7.66699 91.0003 7.66699C44.9766 7.66699 7.66699 44.9766 7.66699 91.0003C7.66699 137.024 44.9766 174.334 91.0003 174.334Z" stroke="#FF8B66" stroke-width="15" />
        </svg>
        <p className='feddup__popup__login__success__container__message'>You are successfully Logged in to FeddUp.</p>
        <button type="button" onClick={onClick} style={{ background: 'rgb(234, 88, 12)' }} className='feddup__popup__login__success__container__button'>Ok</button>
    </div>
}

const logo = <svg width="60" viewBox="0 0 98 93" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M89.9187 25.6025V76.4132H72.5438L65.9004 64.7326L57.724 76.4132H32.6107L21.9522 89.4808L3.04419 25.6025H89.9187Z" fill="#E6570E" stroke="#E6570E" stroke-width="6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M26.2593 53.7823C30.0493 53.7823 33.1217 50.7099 33.1217 46.92C33.1217 43.13 30.0493 40.0576 26.2593 40.0576C22.4694 40.0576 19.397 43.13 19.397 46.92C19.397 50.7099 22.4694 53.7823 26.2593 53.7823Z" fill="#FFE100" />
    <path d="M38.0859 41.4443C42.3202 41.4443 42.3202 44.3645 46.5544 44.3645C50.7886 44.3645 50.7886 41.4443 55.0228 41.4443C59.257 41.4443 59.257 44.3645 63.4912 44.3645C67.7255 44.3645 67.7255 41.4443 71.9597 41.4443C76.1939 41.4443 76.1939 44.3645 80.4281 44.3645" stroke="white" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M38.0859 49.4746C42.3202 49.4746 42.3202 52.3948 46.5544 52.3948C50.7886 52.3948 50.7886 49.4746 55.0228 49.4746C59.257 49.4746 59.257 52.3948 63.4912 52.3948C67.7255 52.3948 67.7255 49.4746 71.9597 49.4746C76.1939 49.4746 76.1939 52.3948 80.4281 52.3948" stroke="white" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M1.43802 21.6601C1.14601 21.3681 1 21.076 1 20.638V2.38707C1 1.94905 1.14601 1.65703 1.43802 1.36502C1.65703 1.14601 2.02205 1 2.38707 1H14.5787C15.0167 1 15.3087 1.14601 15.6008 1.36502C15.8198 1.58403 15.9658 1.94905 15.9658 2.31407C15.9658 2.67909 15.8198 2.9711 15.6008 3.26312C15.3817 3.48213 15.0167 3.62814 14.5787 3.62814H3.77414V10.1985H11.5125C11.8776 10.1985 12.2426 10.3445 12.4616 10.5635C12.7536 10.7825 12.8266 11.1475 12.8266 11.5125C12.8266 11.8776 12.6806 12.1696 12.4616 12.4616C12.1696 12.6806 11.8776 12.8266 11.5125 12.8266H3.77414V20.784C3.77414 21.149 3.62814 21.5141 3.33612 21.8061C3.04411 22.0981 2.75209 22.2441 2.31407 22.2441C2.02205 22.0251 1.65703 21.9521 1.43802 21.6601Z" fill="#93278F" />
    <path d="M1.43802 21.6601C1.14601 21.3681 1 21.076 1 20.638V2.38707C1 1.94905 1.14601 1.65703 1.43802 1.36502C1.65703 1.14601 2.02205 1 2.38707 1H14.5787C15.0167 1 15.3087 1.14601 15.6008 1.36502C15.8198 1.58403 15.9658 1.94905 15.9658 2.31407C15.9658 2.67909 15.8198 2.9711 15.6008 3.26312C15.3817 3.48213 15.0167 3.62814 14.5787 3.62814H3.77414V10.1985H11.5125C11.8776 10.1985 12.2426 10.3445 12.4616 10.5635C12.7536 10.7825 12.8266 11.1475 12.8266 11.5125C12.8266 11.8776 12.6806 12.1696 12.4616 12.4616C12.1696 12.6806 11.8776 12.8266 11.5125 12.8266H3.77414V20.784C3.77414 21.149 3.62814 21.5141 3.33612 21.8061C3.04411 22.0981 2.75209 22.2441 2.31407 22.2441C2.02205 22.0251 1.65703 21.9521 1.43802 21.6601Z" stroke="black" stroke-width="2" />
    <path d="M27.2083 15.3075C26.9893 15.5265 26.6973 15.6725 26.3323 15.6725H16.1118C16.3308 16.9136 16.9148 17.9357 17.7909 18.7387C18.7399 19.5417 19.908 19.9068 21.222 19.9068C21.7331 19.9068 22.3171 19.8338 22.9011 19.6147C23.4851 19.3957 23.9962 19.1767 24.3612 18.8847C24.5802 18.6657 24.9452 18.5927 25.2372 18.5927C25.6023 18.5927 25.8213 18.6657 26.0403 18.8847C26.3323 19.1767 26.5513 19.4687 26.5513 19.8338C26.5513 20.1258 26.4053 20.4178 26.1133 20.6368C25.5293 21.1478 24.7262 21.5128 23.8502 21.8049C22.9011 22.0239 22.0251 22.1699 21.222 22.1699C19.762 22.1699 18.4479 21.8779 17.2798 21.2208C16.1118 20.5638 15.1627 19.6877 14.5787 18.5927C13.9947 17.4976 13.6296 16.1836 13.6296 14.7235C13.6296 13.2634 13.9217 12.0224 14.5787 10.8543C15.1627 9.68623 16.0388 8.81018 17.1338 8.15315C18.2289 7.49612 19.4699 7.2041 20.93 7.2041C22.3171 7.2041 23.4851 7.49612 24.5072 8.15315C25.5293 8.73718 26.2593 9.61323 26.8433 10.7083C27.3544 11.8033 27.6464 13.0444 27.6464 14.5045C27.5004 14.7965 27.4274 15.0885 27.2083 15.3075ZM17.6448 10.5623C16.8418 11.2923 16.3308 12.2414 16.1118 13.4824H25.0912C24.9452 12.3144 24.5072 11.2923 23.7772 10.5623C23.0471 9.83224 22.0981 9.46722 20.857 9.46722C19.5429 9.54022 18.4479 9.90524 17.6448 10.5623Z" fill="#93278F" />
    <path d="M27.2083 15.3075C26.9893 15.5265 26.6973 15.6725 26.3323 15.6725H16.1118C16.3308 16.9136 16.9148 17.9357 17.7909 18.7387C18.7399 19.5417 19.908 19.9068 21.222 19.9068C21.7331 19.9068 22.3171 19.8338 22.9011 19.6147C23.4851 19.3957 23.9962 19.1767 24.3612 18.8847C24.5802 18.6657 24.9452 18.5927 25.2372 18.5927C25.6023 18.5927 25.8213 18.6657 26.0403 18.8847C26.3323 19.1767 26.5513 19.4687 26.5513 19.8338C26.5513 20.1258 26.4053 20.4178 26.1133 20.6368C25.5293 21.1478 24.7262 21.5128 23.8502 21.8049C22.9011 22.0239 22.0251 22.1699 21.222 22.1699C19.762 22.1699 18.4479 21.8779 17.2798 21.2208C16.1118 20.5638 15.1627 19.6877 14.5787 18.5927C13.9947 17.4976 13.6296 16.1836 13.6296 14.7235C13.6296 13.2634 13.9217 12.0224 14.5787 10.8543C15.1627 9.68623 16.0388 8.81018 17.1338 8.15315C18.2289 7.49612 19.4699 7.2041 20.93 7.2041C22.3171 7.2041 23.4851 7.49612 24.5072 8.15315C25.5293 8.73718 26.2593 9.61323 26.8433 10.7083C27.3544 11.8033 27.6464 13.0444 27.6464 14.5045C27.5004 14.7965 27.4274 15.0885 27.2083 15.3075ZM17.6448 10.5623C16.8418 11.2923 16.3308 12.2414 16.1118 13.4824H25.0912C24.9452 12.3144 24.5072 11.2923 23.7772 10.5623C23.0471 9.83224 22.0981 9.46722 20.857 9.46722C19.5429 9.54022 18.4479 9.90524 17.6448 10.5623Z" stroke="black" stroke-width="2" />
    <path d="M43.0505 1.36502C43.2695 1.58403 43.4155 1.94905 43.4155 2.38707V14.6517C43.4155 16.0388 43.1235 17.3528 42.4664 18.4479C41.8094 19.616 40.9333 20.492 39.7653 21.149C38.6702 21.8061 37.4292 22.1711 36.0421 22.1711C34.655 22.1711 33.414 21.8791 32.2459 21.222C31.0778 20.565 30.2018 19.689 29.5448 18.5209C28.8877 17.3528 28.5957 16.1118 28.5957 14.7247C28.5957 13.3376 28.8877 12.0236 29.4717 10.9285C30.0558 9.76045 30.9318 8.88441 31.9539 8.22737C32.9759 7.57034 34.144 7.27832 35.4581 7.27832C36.4801 7.27832 37.5022 7.49734 38.3782 7.93536C39.2543 8.37338 40.0573 8.95741 40.6413 9.76045V2.46008C40.6413 2.02205 40.7873 1.73004 41.0063 1.43802C41.3714 1.14601 41.6634 1 42.1014 1C42.4664 1 42.8314 1.14601 43.0505 1.36502ZM38.4512 19.1049C39.1813 18.6669 39.7653 18.0829 40.1303 17.2798C40.5683 16.4768 40.7143 15.6738 40.7143 14.7247C40.7143 13.7757 40.4953 12.8996 40.1303 12.1696C39.6923 11.4395 39.1813 10.7825 38.4512 10.3445C37.7212 9.90646 36.9181 9.68745 36.0421 9.68745C35.166 9.68745 34.363 9.90646 33.633 10.3445C32.9029 10.7825 32.3189 11.3665 31.8809 12.1696C31.4429 12.8996 31.2238 13.7757 31.2238 14.7247C31.2238 15.6738 31.4429 16.5498 31.8809 17.2798C32.3189 18.0829 32.9029 18.6669 33.633 19.1049C34.363 19.543 35.166 19.762 36.0421 19.762C36.9181 19.762 37.7212 19.543 38.4512 19.1049Z" fill="#93278F" />
    <path d="M43.0504 1.36502C43.2695 1.58403 43.4155 1.94905 43.4155 2.38707V14.6517C43.4155 16.0388 43.1235 17.3528 42.4664 18.4479C41.8094 19.616 40.9333 20.492 39.7653 21.149C38.6702 21.8061 37.4292 22.1711 36.0421 22.1711C34.655 22.1711 33.414 21.8791 32.2459 21.222C31.0778 20.565 30.2018 19.689 29.5448 18.5209C28.8877 17.3528 28.5957 16.1118 28.5957 14.7247C28.5957 13.3376 28.8877 12.0236 29.4717 10.9285C30.0558 9.76045 30.9318 8.88441 31.9539 8.22737C32.9759 7.57034 34.144 7.27832 35.4581 7.27832C36.4801 7.27832 37.5022 7.49734 38.3782 7.93536C39.2543 8.37338 40.0573 8.95741 40.6413 9.76045V2.46008C40.6413 2.02205 40.7873 1.73004 41.0063 1.43802C41.3714 1.14601 41.6634 1 42.1014 1C42.4664 1 42.8314 1.14601 43.0504 1.36502ZM38.4512 19.1049C39.1813 18.6669 39.7653 18.0829 40.1303 17.2798C40.5683 16.4768 40.7143 15.6738 40.7143 14.7247C40.7143 13.7757 40.4953 12.8996 40.1303 12.1696C39.6923 11.4395 39.1813 10.7825 38.4512 10.3445C37.7212 9.90646 36.9181 9.68745 36.0421 9.68745C35.166 9.68745 34.363 9.90646 33.633 10.3445C32.9029 10.7825 32.3189 11.3665 31.8809 12.1696C31.4429 12.8996 31.2238 13.7757 31.2238 14.7247C31.2238 15.6738 31.4429 16.5498 31.8809 17.2798C32.3189 18.0829 32.9029 18.6669 33.633 19.1049C34.363 19.543 35.166 19.762 36.0421 19.762C36.9181 19.762 37.7212 19.543 38.4512 19.1049Z" stroke="black" stroke-width="2" />
    <path d="M58.9652 1.36502C59.2573 1.65703 59.4033 1.94905 59.4033 2.38707V14.6517C59.4033 16.0388 59.1113 17.3528 58.4542 18.4479C57.7972 19.616 56.9211 20.492 55.7531 21.149C54.658 21.8061 53.344 22.0981 51.9569 22.0981C50.5698 22.0981 49.3287 21.8061 48.1607 21.149C46.9926 20.492 46.1166 19.616 45.4595 18.4479C44.8025 17.2798 44.5105 16.0388 44.5105 14.6517C44.5105 13.2646 44.8025 11.9506 45.3865 10.8555C45.9706 9.68745 46.8466 8.8114 47.8687 8.15437C48.8907 7.49734 50.0588 7.20532 51.3729 7.20532C52.3949 7.20532 53.417 7.42433 54.293 7.86235C55.1691 8.30038 55.9721 8.88441 56.5561 9.68745V2.38707C56.5561 1.94905 56.7021 1.65703 56.9211 1.36502C57.2862 1.14601 57.5782 1 58.0162 1C58.3812 1 58.7462 1.14601 58.9652 1.36502ZM54.366 19.1049C55.096 18.6669 55.6801 18.0829 56.0451 17.2798C56.4831 16.4768 56.6291 15.6738 56.6291 14.7247C56.6291 13.7757 56.4101 12.8996 56.0451 12.1696C55.6071 11.4395 55.096 10.7825 54.366 10.3445C53.636 9.90646 52.8329 9.68745 51.9569 9.68745C51.0808 9.68745 50.2778 9.90646 49.5478 10.3445C48.8177 10.7825 48.2337 11.3665 47.7957 12.1696C47.3576 12.8996 47.1386 13.7757 47.1386 14.7247C47.1386 15.6738 47.3576 16.5498 47.7957 17.2798C48.2337 18.0829 48.8177 18.6669 49.5478 19.1049C50.2778 19.543 51.0808 19.762 51.9569 19.762C52.8329 19.762 53.636 19.543 54.366 19.1049Z" fill="#93278F" />
    <path d="M58.9652 1.36502C59.2573 1.65703 59.4033 1.94905 59.4033 2.38707V14.6517C59.4033 16.0388 59.1113 17.3528 58.4542 18.4479C57.7972 19.616 56.9211 20.492 55.7531 21.149C54.658 21.8061 53.344 22.0981 51.9569 22.0981C50.5698 22.0981 49.3287 21.8061 48.1607 21.149C46.9926 20.492 46.1166 19.616 45.4595 18.4479C44.8025 17.2798 44.5105 16.0388 44.5105 14.6517C44.5105 13.2646 44.8025 11.9506 45.3865 10.8555C45.9706 9.68745 46.8466 8.8114 47.8687 8.15437C48.8907 7.49734 50.0588 7.20532 51.3729 7.20532C52.3949 7.20532 53.417 7.42433 54.293 7.86235C55.1691 8.30038 55.9721 8.88441 56.5561 9.68745V2.38707C56.5561 1.94905 56.7021 1.65703 56.9211 1.36502C57.2862 1.14601 57.5782 1 58.0162 1C58.3812 1 58.7462 1.14601 58.9652 1.36502ZM54.366 19.1049C55.096 18.6669 55.6801 18.0829 56.0451 17.2798C56.4831 16.4768 56.6291 15.6738 56.6291 14.7247C56.6291 13.7757 56.4101 12.8996 56.0451 12.1696C55.6071 11.4395 55.096 10.7825 54.366 10.3445C53.636 9.90646 52.8329 9.68745 51.9569 9.68745C51.0808 9.68745 50.2778 9.90646 49.5478 10.3445C48.8177 10.7825 48.2337 11.3665 47.7957 12.1696C47.3576 12.8996 47.1386 13.7757 47.1386 14.7247C47.1386 15.6738 47.3576 16.5498 47.7957 17.2798C48.2337 18.0829 48.8177 18.6669 49.5478 19.1049C50.2778 19.543 51.0808 19.762 51.9569 19.762C52.8329 19.762 53.636 19.543 54.366 19.1049Z" stroke="black" stroke-width="2" />
    <path d="M67.8717 21.5141C66.5576 20.857 65.4625 19.981 64.7325 18.8859C64.0025 17.7179 63.6375 16.4768 63.6375 15.0167V2.38707C63.6375 1.94905 63.7835 1.65703 64.0755 1.36502C64.2945 1.14601 64.6595 1 65.0245 1C65.4625 1 65.7546 1.14601 66.0466 1.36502C66.3386 1.65703 66.4116 1.94905 66.4116 2.38707V14.9437C66.4116 15.8928 66.7036 16.7688 67.2146 17.4989C67.7257 18.2289 68.4557 18.8129 69.3317 19.2509C70.2078 19.689 71.3028 19.908 72.4709 19.908C73.566 19.908 74.588 19.689 75.4641 19.2509C76.3401 18.8129 76.9971 18.2289 77.5082 17.4989C78.0192 16.7688 78.2382 15.8928 78.2382 14.9437V2.38707C78.2382 1.94905 78.3842 1.65703 78.6762 1.36502C78.9682 1.073 79.2603 1 79.6983 1C80.0633 1 80.4283 1.14601 80.6473 1.36502C80.9393 1.65703 81.0123 1.94905 81.0123 2.38707V14.9437C81.0123 16.4038 80.6473 17.6449 79.9173 18.8129C79.1873 19.981 78.1652 20.857 76.8511 21.5141C75.5371 22.1711 74.077 22.4631 72.3979 22.4631C70.7188 22.4631 69.2587 22.1711 67.8717 21.5141Z" fill="#93278F" />
    <path d="M67.8717 21.5141C66.5576 20.857 65.4625 19.981 64.7325 18.8859C64.0025 17.7179 63.6375 16.4768 63.6375 15.0167V2.38707C63.6375 1.94905 63.7835 1.65703 64.0755 1.36502C64.2945 1.14601 64.6595 1 65.0245 1C65.4625 1 65.7546 1.14601 66.0466 1.36502C66.3386 1.65703 66.4116 1.94905 66.4116 2.38707V14.9437C66.4116 15.8928 66.7036 16.7688 67.2146 17.4989C67.7257 18.2289 68.4557 18.8129 69.3317 19.2509C70.2078 19.689 71.3028 19.908 72.4709 19.908C73.566 19.908 74.588 19.689 75.4641 19.2509C76.3401 18.8129 76.9971 18.2289 77.5082 17.4989C78.0192 16.7688 78.2382 15.8928 78.2382 14.9437V2.38707C78.2382 1.94905 78.3842 1.65703 78.6762 1.36502C78.9682 1.073 79.2603 1 79.6983 1C80.0633 1 80.4283 1.14601 80.6473 1.36502C80.9393 1.65703 81.0123 1.94905 81.0123 2.38707V14.9437C81.0123 16.4038 80.6473 17.6449 79.9173 18.8129C79.1873 19.981 78.1652 20.857 76.8511 21.5141C75.5371 22.1711 74.077 22.4631 72.3979 22.4631C70.7188 22.4631 69.2587 22.1711 67.8717 21.5141Z" stroke="black" stroke-width="2" />
    <path d="M93.35 8.15555C94.518 8.81258 95.3941 9.68863 96.0511 10.8567C96.7082 12.0248 97.0002 13.2658 97.0002 14.6529C97.0002 16.04 96.7082 17.354 96.1241 18.4491C95.5401 19.6171 94.6641 20.4932 93.642 21.1502C92.62 21.8073 91.4519 22.0993 90.1378 22.0993C89.1158 22.0993 88.0937 21.8803 87.2177 21.4422C86.3416 21.0042 85.5386 20.4202 84.9546 19.6171V26.9175C84.9546 27.3555 84.8085 27.6476 84.5895 27.9396C84.3705 28.1586 84.0055 28.3046 83.5675 28.3046C83.2025 28.3046 82.8374 28.1586 82.6184 27.9396C82.3264 27.7206 82.2534 27.3555 82.2534 26.9175V14.7259C82.2534 13.3388 82.5454 12.0248 83.2025 10.9297C83.8595 9.76163 84.7355 8.88559 85.9036 8.22855C87.0717 7.57152 88.3127 7.27951 89.6998 7.27951C91.0139 7.2065 92.2549 7.49852 93.35 8.15555ZM92.0359 19.1061C92.766 18.6681 93.35 18.0841 93.788 17.281C94.226 16.551 94.445 15.6749 94.445 14.7259C94.445 13.7768 94.226 12.9008 93.788 12.1708C93.35 11.3677 92.766 10.7837 92.0359 10.3457C91.3059 9.90764 90.5028 9.68863 89.6268 9.68863C88.7508 9.68863 87.9477 9.90764 87.2177 10.3457C86.4876 10.7837 85.9036 11.3677 85.5386 12.1708C85.1006 12.9738 84.9546 13.7768 84.9546 14.7259C84.9546 15.6749 85.1736 16.551 85.5386 17.281C85.9766 18.0111 86.4876 18.6681 87.2177 19.1061C87.9477 19.5441 88.7508 19.7632 89.6268 19.7632C90.5028 19.7632 91.3059 19.5441 92.0359 19.1061Z" fill="#93278F" />
    <path d="M93.35 8.15555C94.518 8.81258 95.3941 9.68863 96.0511 10.8567C96.7082 12.0248 97.0002 13.2658 97.0002 14.6529C97.0002 16.04 96.7082 17.354 96.1241 18.4491C95.5401 19.6171 94.6641 20.4932 93.642 21.1502C92.62 21.8073 91.4519 22.0993 90.1378 22.0993C89.1158 22.0993 88.0937 21.8803 87.2177 21.4422C86.3416 21.0042 85.5386 20.4202 84.9546 19.6171V26.9175C84.9546 27.3555 84.8085 27.6476 84.5895 27.9396C84.3705 28.1586 84.0055 28.3046 83.5675 28.3046C83.2025 28.3046 82.8374 28.1586 82.6184 27.9396C82.3264 27.7206 82.2534 27.3555 82.2534 26.9175V14.7259C82.2534 13.3388 82.5454 12.0248 83.2025 10.9297C83.8595 9.76163 84.7355 8.88559 85.9036 8.22855C87.0717 7.57152 88.3127 7.27951 89.6998 7.27951C91.0139 7.2065 92.2549 7.49852 93.35 8.15555ZM92.0359 19.1061C92.766 18.6681 93.35 18.0841 93.788 17.281C94.226 16.551 94.445 15.6749 94.445 14.7259C94.445 13.7768 94.226 12.9008 93.788 12.1708C93.35 11.3677 92.766 10.7837 92.0359 10.3457C91.3059 9.90764 90.5028 9.68863 89.6268 9.68863C88.7508 9.68863 87.9477 9.90764 87.2177 10.3457C86.4876 10.7837 85.9036 11.3677 85.5386 12.1708C85.1006 12.9738 84.9546 13.7768 84.9546 14.7259C84.9546 15.6749 85.1736 16.551 85.5386 17.281C85.9766 18.0111 86.4876 18.6681 87.2177 19.1061C87.9477 19.5441 88.7508 19.7632 89.6268 19.7632C90.5028 19.7632 91.3059 19.5441 92.0359 19.1061Z" stroke="black" stroke-width="2" />
</svg>
