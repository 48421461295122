
const eKey = 'feddup'
export function encrypt(message) {
    if (typeof message === 'object') {
        message = JSON.stringify(message);
    }
    let encryptedMessage = '';
    for (let i = 0; i < message.length; i++) {
        const char = message[i];
        const charCode = char.charCodeAt(0);
        const keyCharCode = eKey.charCodeAt(i % eKey.length); // Retrieve the character code of the key at the corresponding position
        const encryptedCharCode = (charCode + keyCharCode) % 256; // Perform simple shift using the key value
        const encryptedChar = String.fromCharCode(encryptedCharCode);
        encryptedMessage += encryptedChar;
    }
    return encryptedMessage;
}

export const decrypt = (encryptedMessage) => {
    let decryptedMessage = '';
    for (let i = 0; i < encryptedMessage.length; i++) {
        const char = encryptedMessage[i];
        const charCode = char.charCodeAt(0);
        const keyCharCode = eKey.charCodeAt(i % eKey.length); // Retrieve the character code of the key at the corresponding position
        const decryptedCharCode = (charCode - keyCharCode + 256) % 256; // Reverse the shift using the key value
        const decryptedChar = String.fromCharCode(decryptedCharCode);
        decryptedMessage += decryptedChar;
    }
    if (decryptedMessage.includes('{') && decryptedMessage.includes('}')) {
        decryptedMessage = JSON.parse(decryptedMessage);
    }
    return decryptedMessage;
}