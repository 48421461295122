import { useEffect, useState } from "react";
import { authenticatedButtons, feedbackIcons, privatePage, unAuthenticatedButtons } from './contants';
import Login from "./Login";
// import fillTag from '../public/images/fill_tag.png';
// import speechbubble from './images/speechbubble.svg';
// import emptyTag from "./images/empty_tag.png";
import { config } from './Config/Config';
import AddImage from "./AddImage";
import Review from "./Rate&Review";
import axios from "axios";
import { decrypt, encrypt } from "./tool";
const fillTag = `${config.host}/images/fill_tag.png`;
const emptyTag = `${config.host}/images/empty_tag.png`;

let newTabIcon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
</svg>

let backIcon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 transform transition-all duration-200 ease-in-out h-5">
    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
</svg>

let nextIcon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 transform transition-all duration-200 ease-in-out h-5">
    <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
</svg>

let data = [
    {
        id: 1,
        title: 'Sign In',
    },
    {
        id: 2,
        title: 'Post Anonymously',
    },
    {
        id: 3,
        title: 'What is feddup?',
        link: config.webUrl
    },
]
let data2 = [
    {
        id: 1,
        title: 'Continue',
    },
    {
        id: 2,
        title: 'Post Anonymously',
    },
    {
        id: 3,
        title: 'What is feddup?',
        link: config.webUrl
    },
]

let data3 = [
    {
        id: 1,
        title: 'Sign In',
    },
    {
        id: 3,
        title: 'What is feddup?',
        link: config.webUrl
    },
]
let data4 = [
    {
        id: 1,
        title: 'Continue',
    },
    {
        id: 3,
        title: 'What is feddup?',
        link: config.webUrl
    },
]

const openInNewTab = url => {
    if (!url?.substring(0, 4)?.includes('http')) {
        url = 'https://' + url
    }
    return window.open(url, '_blank')
};

function Popup({ productDetail }) {
    const [index, setIndex] = useState();
    const [user, setUser] = useState();
    const [limit, setLimit] = useState({ rating: { limit: false }, feedback: { limit: false }, suggestion: { limit: false } })
    const [ipAddress, setIpAddress] = useState();
    const [status, setStatus] = useState(localStorage.getItem('feddup__user'));
    const [buttons, setButtons] = useState(status ? productDetail.pageType == 'Private' ? data4 : data2 : productDetail.pageType == 'Private' ? data3 : data);
    const [isAnonymous, setIsAnonymous] = useState(true);
    const [active, setActive] = useState('');
    const [show, setShow] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [ratingQuestions, setRatingQuestions] = useState([]);
    const [feedbackQuestions, setFeedbackQuestions] = useState([]);
    const [infoText, setInfoText] = useState({ title: productDetail.productName, description: productDetail.productDescription, productVersion: productDetail.productVersion })
    const [isAccess, setIsAccess] = useState(true);
    const [feedbackForm, setFeedbackForm] = useState();
    const [ratingAccess, setRatingAccess] = useState(true);
    const [follow, setFollow] = useState(false)

    const getFeedbackForm = async () => {
        let userRes = await fetch(config.userUrl + `user/getCurrentFeedbackFormAnonymous`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "productId": productDetail.productId,
                "versionId": productDetail.id
            })
        }).then(res => res.json())
            .then(res => {
                if (res.status === true) {
                    setFeedbackForm(res.feedbackForm[0])
                } else {
                    console.log("error")
                }
            })
            .catch(e => null);
    }

    const validateReviewAdded = async () => {
        let ip = await fetch('https://api.db-ip.com/v2/free/self');
        if (ip) {
            ip = await ip.json();
            setIpAddress(ip.ipAddress)
        }
        var headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + (localStorage.getItem('feddup__user') ? decrypt(localStorage.getItem('feddup__user')) : ''),
            "applicationType": "web"
        }
        var tokenId = encrypt({ "companyId": productDetail?.companyId, "productId": productDetail?.productId, userIP: ip.ipAddress })

        var payload = {
            "tokenId": tokenId,
            "versionId": productDetail.id,
            formId: feedbackForm?.id,
            type: 'rating',
            email: user?.email
        }
        if (productDetail.id) {
            axios.post(config.userUrl + 'user/validateReviewAdded', payload, { headers: headers })
                .then(function (response) {
                    if (response.data.status === true) {
                        setIsAccess(response.data?.access)
                        setRatingAccess(response?.data?.ratingAccess)
                    } else {
                        console.log("error", payload);
                    }
                })
                .catch(function (error) {
                    console.log("Error", error);
                });
        }
    };

    useEffect(() => { getFeedbackForm() }, [])

    useEffect(() => {
        if (productDetail) {
            validateReviewAdded()
        }
    }, [productDetail?.id, user, feedbackForm])

    const getUser = async () => {
        let ip = await fetch('https://api.db-ip.com/v2/free/self');
        if (ip) {
            ip = await ip.json();
            setIpAddress(ip.ipAddress)
        }
        let id = localStorage.getItem("feddup__user") ? decrypt(localStorage.getItem("feddup__user")) : ''
        let userRes = await fetch(config.apiUrl + `auth/getUserDetailsByToken`, {
            method: "POST",
            headers: {
                "Content-Type": 'application/json',
                "Authorization": "Bearer " + id,
                "applicationType": "web"
            },
        }).then(res => res.json()).catch(e => null);
        if (userRes.user) {
            getButtons(userRes.user);
            setFollow(productDetail?.follow)
            setUser({ ...userRes.user[0], name: userRes.user[0].userName });
            setIsAnonymous(false)
        }
        else {
            setUser(null);
            setFollow(false)
        }
    }

    const getButtons = async (auth) => {
        // let res = await fetch(`http://localhost:5000/${auth ? 'authenticatedButtons' : 'unAuthenticatedButtons'}`).then(res => res.json());
        // setButtons(res)
        if (auth) {
            if (productDetail.pageType == 'Private' && !productDetail.follow) {
                setButtons(privatePage)
            }
            else {
                setButtons(authenticatedButtons)
            }
        } else {
            setButtons(unAuthenticatedButtons)
        }
    }

    useEffect(() => {
        if (user) {
            setIsAnonymous(false);
        }
    }, [user])
    useEffect(() => {
        if (status) {
            if (productDetail.pageType == 'Private') {
                setButtons(data4)
            }
            else
                setButtons(data2)
        } else {
            if (productDetail.pageType == 'Private') {
                setButtons(data3)
            }
            else
                setButtons(data)
        }
    }, [status, show])

    useEffect(() => {
        getUser();
    }, [])
    useEffect(() => {
        if (active?.title) {
            if (active.title.toLowerCase().includes('feddback')) {
                setInfoText({ title: productDetail.productName, description: "Public community post to shed light on generic bugs and features not working as intended", productVersion: productDetail.version })
            } else if (active.title.toLowerCase().includes('suggestion')) {
                setInfoText({ title: productDetail.productName, description: "Public community post where you can suggest new features and updates", productVersion: productDetail.version })
            } else {
                setInfoText({ title: productDetail.productName, description: productDetail.productDescription, productVersion: productDetail.version })
            }
        } else {
            setInfoText({ title: productDetail.productName, description: productDetail.productDescription, productVersion: productDetail.version })
        }
    }, [active])
    const logout = async () => {
        setUser();
        localStorage.removeItem("feddup__user")
        setStatus()
        // await getButtons();
        setFollow(false)
        setIsAnonymous(true);
        setActive()
        setButtons(data);
    }
    const getTitle = () => {
        if (active && active.title) return active.title
        else if ((active && !active.title || isAnonymous)) return productDetail.pageType == 'Private' ? 'Hi there !!' : 'Hi anonymous!'
        else if (user && user.name) return `Hello, ${user.name}`
        else return 'Hi there !!'
    }

    return (
        <>
            <Login isLogin={isLogin} setIsLogin={setIsLogin} setUser={setUser} getButtons={getButtons} setStatus={setStatus} />
            <div className={`feddup__fullscreen__bg ${show ? 'inline-block' : 'hidden'}`} onClick={() => setShow(false)} style={{ zIndex: 50001 }}>
            </div>
            <div className="feddup__icon_position" style={{ zIndex: 50002 }}>
                <div className={`feddup__container ${show ? 'inline-block' : 'hidden'}`}>
                    <div className="feddup__popup__tail" ></div>
                    <div className="feddup__container__topbar">
                        <div className="feddup__container__topbar_details">
                            {active && <svg width="16" height="16" onClick={() => setActive('')} className='cursor-pointer flex-shrink-0' viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.292892 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM16 7L0.999999 7V9L16 9V7Z" fill="black" />
                            </svg>}
                            <span className={`feddup__container__topbar_details__title ${active && active.title ? "text-[#E6570E]" : "text-black"}`} > {getTitle()}</span>
                        </div>
                        <div className="feddup__container__topbar_details">
                            <div className="group" style={{ position: 'relative' }}>
                                <svg className="feddup__svg__icon" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.9 12.7004V12.2004H9.4C9.21435 12.2004 9.0363 12.1266 8.90502 11.9954C8.77375 11.8641 8.7 11.686 8.7 11.5004C8.7 11.3147 8.77375 11.1367 8.90502 11.0054C9.0363 10.8741 9.21435 10.8004 9.4 10.8004H10.6C10.7857 10.8004 10.9637 10.8741 11.095 11.0054C11.2262 11.1367 11.3 11.3147 11.3 11.5004V15.1004V15.6004H11.8C11.9857 15.6004 12.1637 15.6741 12.295 15.8054C12.4262 15.9367 12.5 16.1147 12.5 16.3004C12.5 16.486 12.4262 16.6641 12.295 16.7954C12.1637 16.9266 11.9857 17.0004 11.8 17.0004H10.6C10.4143 17.0004 10.2363 16.9266 10.105 16.7954C9.97375 16.6641 9.9 16.486 9.9 16.3004V12.7004ZM17.7418 18.6422C19.6359 16.7481 20.7 14.1791 20.7 11.5004C20.7 8.8217 19.6359 6.25273 17.7418 4.35861C15.8477 2.46449 13.2787 1.40039 10.6 1.40039C7.92131 1.40039 5.35234 2.46449 3.45822 4.35861C1.5641 6.25273 0.5 8.8217 0.5 11.5004C0.5 14.1791 1.5641 16.7481 3.45822 18.6422C5.35234 20.5363 7.92131 21.6004 10.6 21.6004C13.2787 21.6004 15.8477 20.5363 17.7418 18.6422ZM11.3 6.70039C11.3 6.88604 11.2262 7.06409 11.095 7.19537C10.9637 7.32664 10.7857 7.40039 10.6 7.40039C10.4143 7.40039 10.2363 7.32664 10.105 7.19537C9.97375 7.06409 9.9 6.88604 9.9 6.70039C9.9 6.51474 9.97375 6.33669 10.105 6.20542C10.2363 6.07414 10.4143 6.00039 10.6 6.00039C10.7857 6.00039 10.9637 6.07414 11.095 6.20542C11.2262 6.33669 11.3 6.51474 11.3 6.70039Z" fill="white" stroke="#C4C4C4" />
                                </svg>
                                <div className="feddup__container__topbar_details__box" style={{ zIndex: "1003" }}>
                                    <h1 style={{ fontWeight: '500', fontSize: '1rem', lineHeight: '1.5rem', color: '#222' }}>{infoText?.title}</h1>
                                    <p style={{ fontSize: '0.75rem', lineHeight: '1rem', color: 'grey', minWidth: '150px', maxWidth: '230px' }} className="feddup__feddup__feddup__feddup__text-xs feddup__text-gray-500">{infoText?.description || ('Powered by ' + productDetail?.companyName)}</p>
                                    <div className="flex items-center justify-between">
                                        <p style={{ fontSize: '0.65rem', lineHeight: '1rem', color: '#fff', background: '#E6570E', padding: '2px 6px', borderRadius: 20 }}>{productDetail.pageType || 'Public'}</p>
                                        <p className="feddup__feddup__feddup__text_xs text-right my-2" style={{ color: 'grey' }}>{infoText?.productVersion || '0.1.1'}</p>
                                    </div>
                                </div>
                            </div>

                            {user && <svg onClick={logout} className="feddup__svg__icon" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 20.5C4.477 20.5 0 16.023 0 10.5C0 4.977 4.477 0.500003 10 0.500003C11.5527 0.498836 13.0842 0.859775 14.4729 1.55414C15.8617 2.24851 17.0693 3.25718 18 4.5H15.29C14.1352 3.48176 12.7112 2.81836 11.1887 2.5894C9.66625 2.36044 8.11007 2.57566 6.70689 3.20922C5.30371 3.84277 4.11315 4.86776 3.27807 6.16119C2.44299 7.45462 1.99887 8.96153 1.999 10.5011C1.99913 12.0407 2.4435 13.5475 3.27879 14.8408C4.11409 16.1341 5.30482 17.1589 6.7081 17.7922C8.11139 18.4255 9.66761 18.6405 11.19 18.4113C12.7125 18.1821 14.1364 17.5184 15.291 16.5H18.001C17.0702 17.743 15.8624 18.7517 14.4735 19.4461C13.0846 20.1405 11.5528 20.5013 10 20.5ZM17 14.5V11.5H9V9.5H17V6.5L22 10.5L17 14.5Z" fill="#E6570E" />
                            </svg>}

                            <svg className="feddup__svg__icon" onClick={() => { setShow(false); setButtons(data); setIsAnonymous(false) }} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.1871 0.5L9 7.68714L1.81286 0.5L0 2.31286L7.18714 9.5L0 16.6871L1.81286 18.5L9 11.3129L16.1871 18.5L18 16.6871L10.8129 9.5L18 2.31286L16.1871 0.5Z" fill="black" />
                            </svg>
                        </div>
                    </div>
                    {
                        productDetail.pageType == 'Private' && !productDetail.follow
                            ?
                            <></>
                            :
                            <p className="feddup__signup__text">{isAnonymous ? active ? (active.description || '') : 'Sign up for free to unlock more of feddup' : user ? (active?.desc || '') : 'How do you feel about product ?'}</p>
                    }
                    {
                        productDetail.pageType == 'Private' && !follow
                            ?
                            (buttons?.length > 2 || buttons[0]?.title == "Sign In")
                                ?
                                <div className="flex flex-col items-center justify-center pt-4 pb-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-orange-500">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                                    </svg>
                                    <h1 className="text-xl font-medium text-orange-500 text-center">This page is private</h1>
                                    <p className="text-sm text-center my-1 px-4" style={{ color: '#222' }}>You need to follow this page to provide, reviews, feddbacks & suggestions</p>
                                    <p className="my-2 text-sm" style={{ color: '#222' }}>Click on visit feddup page to follow</p>
                                </div>
                                :
                                !active && !user
                                    ?
                                    <>
                                        <div className="flex flex-col items-center justify-center pt-4 pb-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-orange-500">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                                            </svg>
                                            <h1 className="text-xl font-medium text-orange-500 text-center">This page is private</h1>
                                            <p className="text-sm text-center my-1" style={{ color: '#222' }}>You will have to sign in to continue further.</p>
                                        </div>
                                    </>
                                    :
                                    <></>
                            :
                            <></>
                    }
                    <>
                        {
                            active ?
                                <>
                                    {
                                        active.title.toLowerCase().includes('rat') ?
                                            <Review validateReviewAdded={validateReviewAdded} ratingAccess={ratingAccess} user={user} productDetail={productDetail} setActive={setActive} limit={limit?.feedback} placeholder={active.placeholder} isAnonymous={isAnonymous} getButtons={getButtons} />
                                            :
                                            active.title.toLowerCase().includes('feddback') ?
                                                <Feedback user={user} productDetail={productDetail} setActive={setActive} limit={limit?.feedback} placeholder={active.placeholder} isAnonymous={isAnonymous} getButtons={getButtons} />
                                                :
                                                active.title.toLowerCase().includes('suggestion') ?
                                                    <Suggestions user={user} limit={limit?.suggestion} productDetail={productDetail} setActive={setActive} placeholder={active.placeholder} isAnonymous={isAnonymous} getButtons={getButtons} />
                                                    : ''
                                    }
                                </>
                                :
                                <>
                                    {
                                        buttons.map(item => (
                                            <button key={item.id} style={{ border: "none", outline: "none" }} onClick={() => {
                                                if (item.title == 'Post Anonymously') {
                                                    setIsAnonymous(true); getButtons();
                                                } else if (item.link) {
                                                    if (item.title == "Visit FeddUp Page") {
                                                        // `?productId=${productDetail.productId}`
                                                        // openInNewTab(item.link + `by/${productDetail.companyName}/${productDetail.productName}/${productDetail.productType}`)
                                                        //  openInNewTab(item.link + `by/?companyName=${productDetail.companyName}&productName=${productDetail.productName}&productType=${productDetail.productType}`)
                                                        openInNewTab(item.link + `by/${productDetail.companyName}/${productDetail.productName}/${productDetail.productType}`)
                                                        //    openInNewTab(item.link+`by/${productDetail.companyName}/${productDetail.productName}/${productDetail.productType}`)
                                                    } else if (item.title == "Help") {
                                                        openInNewTab(productDetail.productHelp)
                                                    } else if (item.title == "FAQs") {
                                                        openInNewTab(productDetail.productFaq)
                                                    } else {
                                                        openInNewTab(item.link);
                                                    }
                                                }
                                                else if (item.title.toLowerCase().includes('continue')) {
                                                    getButtons(true)
                                                }
                                                else if (item.title.toLowerCase().includes('sign')) {
                                                    setIsLogin(true);
                                                    setShow(false)
                                                }
                                                else if (item.title.toLowerCase().includes('rat') || item.title.toLowerCase().includes('feddback')) {
                                                    (!isAccess && item.title.toLowerCase().includes('rat')) ? console.log(item)
                                                        :
                                                        setActive(item)
                                                }
                                                else
                                                    setActive(item)
                                            }
                                            }
                                                disabled={!isAccess && item.title?.toLowerCase().includes('rat')}
                                                className={`feddup__popup__button disabled:bg-gray-400 ${item.title.toLowerCase().includes('sign') ? "bg-[#FFB858]" : "feddup-bg"}`}><span>{!isAccess ? item?.title?.includes('Rat') ? "Review Already Provided" : item.title : item.title}</span>{item.link && newTabIcon}</button>
                                        ))
                                    }
                                </>
                        }
                    </>

                </div>

                <div className="group" style={{ position: 'relative' }}>
                    <svg width="43" height="55" style={{ width: '3.5rem', cursor: 'pointer' }} onClick={() => setShow(!show)} viewBox="0 0 43 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_1399_6432)">
                            <path d="M37 49H10.7504V40L16.7848 36.5588L10.7504 32.3235V19.3151L3.99937 13.7941L37 4L37 49Z" fill="white" />
                            <path d="M37 49H10.7504V40L16.7848 36.5588L10.7504 32.3235V19.3151L3.99937 13.7941L37 4L37 49Z" stroke="#FFB858" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <path d="M35 47H8.75035V38L14.7848 34.5588L8.75035 30.3235V17.3151L1.99937 11.7941L35 2L35 47Z" fill="#E6570E" stroke="#E6570E" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M20.4408 14.0252C20.4408 15.9884 22.0281 17.5798 23.986 17.5798C25.944 17.5798 27.5312 15.9884 27.5312 14.0252C27.5312 12.062 25.944 10.4706 23.986 10.4706C22.0281 10.4706 20.4408 12.062 20.4408 14.0252Z" fill="#FFB858" />
                        <path d="M26.8164 20.1512C26.8164 22.3445 25.3078 22.3445 25.3078 24.5378C25.3078 26.7311 26.8164 26.7311 26.8164 28.9244C26.8164 31.1176 25.3078 31.1176 25.3078 33.3109C25.3078 35.5042 26.8164 35.5042 26.8164 37.6975C26.8164 39.8907 25.3078 39.8907 25.3078 42.084" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M22.668 20.1512C22.668 22.3445 21.1594 22.3445 21.1594 24.5378C21.1594 26.7311 22.668 26.7311 22.668 28.9244C22.668 31.1176 21.1594 31.1176 21.1594 33.3109C21.1594 35.5042 22.668 35.5042 22.668 37.6975C22.668 39.8907 21.1594 39.8907 21.1594 42.084" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        <defs>
                            <filter id="filter0_d_1399_6432" x="2.5" y="2.5" width="40" height="52" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dx="2" dy="2" />
                                <feGaussianBlur stdDeviation="1" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1399_6432" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1399_6432" result="shape" />
                            </filter>
                        </defs>
                    </svg>
                    {!show && <div className="feddup__popup__info__container">
                        <div>
                            <svg style={{ width: "293px" }} viewBox="0 0 191 77" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.5 10C0.5 4.7533 4.75329 0.5 10 0.5H172.297C177.544 0.5 181.797 4.7533 181.797 10V61.5751C181.797 63.1622 182.278 64.7121 183.175 66.0208L189.728 75.5722C189.651 75.5282 189.572 75.4894 189.49 75.456L161.658 64.1309C160.401 63.6196 159.057 63.3566 157.7 63.3566H10C4.7533 63.3566 0.5 59.1033 0.5 53.8566V10Z" fill="white" stroke="#CFD8DC" />
                            </svg>
                            <p class="feddup__popup__info__container__text" style={{ color: '#222' }}>Provide feedback & suggestions using <a href={`${config.webUrl}`} target="_blank">feddup.me</a></p>
                        </div>
                    </div>}
                </div>
            </div >
        </>
    );
}
export default Popup;



const Feedback = ({ user, limit, placeholder, productDetail, setActive, isAnonymous, getButtons }) => {
    const [feedback, setFeedback] = useState('');
    const [title, setTitle] = useState('');
    const [image, setImage] = useState('');
    const [defaultTags, setDefaultTags] = useState([])
    const [selectedTags, setSelectedTags] = useState([])
    const [success, setSuccess] = useState(false);
    useEffect(() => {
        dTags();
    }, [])
    const handleSubmit = async () => {
        if (feedback?.length > 0 && feedback?.length <= 120) {
            var tokenId = encrypt({ "companyId": productDetail.companyId, "productId": productDetail.productId })
            let tagsData = [];
            tagsData = defaultTags.filter(item => (item.isSelected === true) ? item.tag : null)
            let headers;
            if (user && !isAnonymous) {
                headers = {
                    'Accept': 'application/json',
                    "Authorization": "Bearer " + decrypt(localStorage.getItem('feddup__user')),
                    'Content-Type': 'application/json'
                }
            } else {
                headers = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }

            let userRes = await fetch(config.userUrl + `user/createProductFeedbackByUser`, {
                method: "POST",
                headers,
                body: JSON.stringify({
                    "tokenId": tokenId,
                    "title": title,
                    "feedback": feedback,
                    "feedbackImage": image,
                    "tag": tagsData.map(item => item.tag),
                    "versionId": productDetail?.id
                })
            }).then(res => res.json()).then(data => {
                getButtons()
                setSuccess(true)
                // setActive('')
            }).catch(e => null);
        }
        else alert("Feedback must be between 1-120 characters")
    }
    const dTags = async () => {
        let userRes = await fetch(config.userUrl + `user/getDefaultTag`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "productId": productDetail.productId
            })
        }).then(res => res.json())
            .then(res => {
                let response = res.getDefaultTag.tag.split(",")
                let defaultTagsArray = [];
                if (res.status === true) {
                    for (let i = 0; i < response.length; i++) {
                        defaultTagsArray[i] = { "tag": response[i], "isSelected": false }
                    }
                    setDefaultTags(defaultTagsArray)
                } else {
                    console.log("error")
                }
            })
            .catch(e => null);
    }
    const handleTags = (e, tag) => {
        const updated = defaultTags.map(data => (data.tag === tag) ?
            { ...data, isSelected: !data.isSelected } : data)
        setDefaultTags(updated)
        setSelectedTags(updated)
    }
    const onChangeImage = (data) => {
        setImage(data)
    }

    if (success) {
        return (
            <div className="flex flex-col items-center justify-center min-h-[300px]">
                <svg width="101" height="101" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1848_8649)">
                        <path d="M50.6139 100.919C78.0712 100.919 100.33 78.5334 100.33 50.9192C100.33 23.305 78.0712 0.919189 50.6139 0.919189C23.1565 0.919189 0.897949 23.305 0.897949 50.9192C0.897949 78.5334 23.1565 100.919 50.6139 100.919Z" fill="#E6570E" />
                        <path d="M23.9802 53.5977L45.287 75.0266L77.2471 26.812" stroke="#FFB858" stroke-width="10" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                        <clipPath id="clip0_1848_8649">
                            <rect width="100" height="100" fill="white" transform="translate(0.548828 0.919189)" />
                        </clipPath>
                    </defs>
                </svg>
                <h1 className="text-center mt-4 font-medium" style={{ color: '#222' }}>Thanks for posting</h1>
            </div>
        )
    }
    return (
        <>
            {
                limit.limit ?
                    <>
                        <div className="feddup__popup__rating__topbar">
                            <p style={{ textAlign: 'center' }}>{limit.message}</p>
                            {!user && <button style={{ border: "none", outline: "none", color: 'grey' }}><span>Sign Up</span></button>}
                        </div>
                    </>
                    :
                    <>
                        <div style={{ position: 'relative' }}>
                            <input name="title" id="title" value={title} onChange={(e) => { if (e.target.value.length <= 100) setTitle(e.target.value) }} placeholder={"Title..."} style={{ color: '#222' }} className="feddup__popup__rating__container__input"></input>
                            <textarea name="feedback" id="feedback" value={feedback} onChange={(e) => { if (e.target.value.length <= 120) setFeedback(e.target.value) }} placeholder={placeholder || "Write your thoughts..."} style={{ color: '#222' }} className="mt-2 feddup__popup__rating__container__textarea"></textarea>
                            <p className="feddup__feddup__feddup__text-xs feddup__text-gray-500 feddup__popup__text__count" style={{ color: 'grey' }}>
                                {
                                    feedback?.length ?
                                        `${feedback.length}/120 character`
                                        :
                                        'Max 120 characters'
                                }
                            </p>
                        </div>
                        <div className="mt-1 uploadImageContainer"> <AddImage onChangeImage={onChangeImage} /></div>
                        <div className="feddup__popup__tag__wrapper">
                            {defaultTags.map((data, i) => (
                                <button key={i}
                                    style={{ border: "none", outline: "none", fontFamily: "Poppins,sans-serif" }}
                                    variant="default" size="sm" value={data.tag}
                                    onClick={(e) => handleTags(e, data.tag)}>
                                    {data.isSelected ?
                                        <div style={{ height: '30px' }}>
                                            <img src={fillTag} style={data.tag.length >= 15
                                                ? { width: "200px", height: "28px", marginLeft: "3px" }
                                                : { width: "130px", height: "28px", marginLeft: "3px" }} />
                                            <div className={data.tag.length >= 15 ? "tag-data-fill" : "tag-data-fill_"} >{data.tag}</div>
                                        </div> :
                                        <div style={{ height: '30px' }}>
                                            <img src={emptyTag} style={data.tag.length >= 15 ? { width: "200px", height: "28px", marginLeft: "3px" } : { width: "130px", height: "28px", marginLeft: "3px" }} />
                                            <div className={data.tag.length >= 15 ? "tag-data-empty" : "tag-data-empty_"} >{data.tag}</div>
                                        </div>}
                                </button>
                            ))}
                        </div>
                        <div className="relative group">
                            {(title.length != 0) && (feedback.length != 0) ?
                                <button style={{ border: "none", outline: "none" }}
                                    className="feddup__popup__rating__container__submit feddup-bg"
                                    onClick={handleSubmit}>Submit</button> :
                                <button style={{ border: "none", outline: "none" }}
                                    className="feddup__popup__rating__container__submit feddup-grey-bg "
                                    disabled="true">Submit</button>
                            }
                            {(title.length != 0) && (feedback.length != 0) ?
                                "" :
                                <div className="feddup__popup__feedback__validation__div" style={{ zIndex: "1003" }}>
                                    <p className="feddup__feddup__feddup__text-xs feddup__text-gray-500" style={{ color: 'gray' }}>Please add title and description</p>
                                </div>
                            }
                        </div>

                    </>}
        </>
    )
}


const Suggestions = ({ user, limit, productDetail, setActive, placeholder, isAnonymous }) => {
    const [suggestion, setSuggestion] = useState('');
    const [title, setTitle] = useState('');
    const [image, setImage] = useState('');
    const [defaultTags, setDefaultTags] = useState([])
    const [selectedTags, setSelectedTags] = useState([])
    const [success, setSuccess] = useState(false)
    useEffect(() => {
        dTags();
    }, [])
    const handleSubmit = async () => {
        if (suggestion?.length > 0 && suggestion?.length <= 120) {
            var tokenId = encrypt({ "companyId": productDetail.companyId, "productId": productDetail.productId })
            let tagsData = [];
            tagsData = defaultTags.filter((item, i) => item.isSelected === true ? item.tag : null)
            let headers;
            if (user && !isAnonymous) {
                headers = {
                    'Accept': 'application/json',
                    "Authorization": "Bearer " + decrypt(localStorage.getItem('feddup__user')),
                    'Content-Type': 'application/json'
                }
            } else {
                headers = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
            let userRes = await fetch(config.userUrl + `user/createProductSuggestionByUser`, {
                method: "POST",
                headers,
                body: JSON.stringify({
                    "tokenId": tokenId,
                    "title": title,
                    "suggestion": suggestion,
                    "suggestionImage": image,
                    "tag": tagsData.map(item => item.tag),
                    "versionId": productDetail?.id
                })
            }).then(res => res.json()).then(() => setSuccess(true)).catch(e => null);
        }
        else alert("Suggestion must be between 1-120 characters")
    }
    const dTags = async () => {
        let userRes = await fetch(config.userUrl + `user/getDefaultTag`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "productId": productDetail.productId
            })
        }).then(res => res.json())
            .then(res => {
                let response = res.getDefaultTag.tag.split(",")
                let defaultTagsArray = [];
                if (res.status === true) {
                    for (let i = 0; i < response.length; i++) {
                        defaultTagsArray[i] = { "tag": response[i], "isSelected": false }
                    }
                    setDefaultTags(defaultTagsArray)
                } else {
                    console.log("error")
                }
            })
            .catch(e => null);
    }
    const handleTags = (e, tag) => {
        const updated = defaultTags.map(data => (data.tag === tag) ?
            { ...data, isSelected: !data.isSelected } : data)
        setDefaultTags(updated)
        setSelectedTags(updated)
    }
    const onChangeImage = (data) => {
        setImage(data)
    }
    if (success) {
        return (
            <div className="flex flex-col items-center justify-center min-h-[300px]">
                <svg width="101" height="101" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1848_8649)">
                        <path d="M50.6139 100.919C78.0712 100.919 100.33 78.5334 100.33 50.9192C100.33 23.305 78.0712 0.919189 50.6139 0.919189C23.1565 0.919189 0.897949 23.305 0.897949 50.9192C0.897949 78.5334 23.1565 100.919 50.6139 100.919Z" fill="#E6570E" />
                        <path d="M23.9802 53.5977L45.287 75.0266L77.2471 26.812" stroke="#FFB858" stroke-width="10" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                        <clipPath id="clip0_1848_8649">
                            <rect width="100" height="100" fill="white" transform="translate(0.548828 0.919189)" />
                        </clipPath>
                    </defs>
                </svg>
                <h1 className="text-center mt-4 font-medium" style={{ color: '#222' }}>Thanks for posting</h1>
            </div>
        )
    }
    return (
        <>
            {
                limit.limit ?
                    <>
                        <div className="feddup__popup__rating__topbar">
                            <p style={{ textAlign: 'center' }}>{limit.message}</p>
                            {!user && <button style={{ border: "none", outline: "none", color: 'grey' }}><span>Sign Up</span></button>}
                        </div>
                    </>
                    :
                    <>
                        <div style={{ position: 'relative' }}>
                            <input name="title" id="title" value={title} onChange={(e) => { if (e.target.value.length <= 100) setTitle(e.target.value) }} placeholder={"Title..."} style={{ color: '#222' }} className="feddup__popup__rating__container__input"></input>
                            <textarea name="suggestion" id="suggestion" value={suggestion} onChange={(e) => { if (e.target.value.length <= 120) setSuggestion(e.target.value) }} placeholder={placeholder || "Write your thoughts..."} style={{ color: '#222' }} className="mt-2 feddup__popup__rating__container__textarea"></textarea>
                            <p className="feddup__feddup__feddup__text-xs feddup__text-gray-500 feddup__popup__text__count" style={{ color: 'grey' }}>
                                {
                                    suggestion?.length ?
                                        `${suggestion.length}/120 character`
                                        :
                                        'Max 120 characters'
                                }
                            </p>
                        </div>
                        <div className="mt-1 uploadImageContainer"> <AddImage onChangeImage={onChangeImage} /></div>
                        <div className="feddup__popup__tag__wrapper" style={{ margin: '16px 0' }}>
                            {defaultTags.map((data, i) => (
                                <button key={i}
                                    style={{ border: "none", outline: "none", fontFamily: "Poppins,sans-serif" }}
                                    variant="default" size="sm" value={data.tag}
                                    onClick={(e) => handleTags(e, data.tag)}>
                                    {data.isSelected ?
                                        <div style={{ height: '30px' }}>
                                            <img src={fillTag} style={data.tag.length >= 15 ? { width: "200px", height: "28px", marginLeft: "3px" } : { width: "130px", height: "28px", marginLeft: "3px" }} />
                                            <div className={data.tag.length >= 15 ? "tag-data-fill" : "tag-data-fill_"} >{data.tag}</div>
                                        </div> :
                                        <div style={{ height: '30px' }}>
                                            <img src={emptyTag} style={data.tag.length >= 15 ? { width: "200px", height: "28px", marginLeft: "3px" } : { width: "130px", height: "28px", marginLeft: "3px" }} />
                                            <div className={data.tag.length >= 15 ? "tag-data-empty" : "tag-data-empty_"} >{data.tag}</div>
                                        </div>}
                                </button>
                            ))}
                        </div>
                        <div className="relative group">
                            {(title.length != 0) && (suggestion.length != 0) ?
                                <button style={{ border: "none", outline: "none" }}
                                    className="block w-full max-w-[260px] text-white rounded-2xl feddup-bg text-base font-medium mx-auto py-2 px-6 mb-4"
                                    onClick={handleSubmit}>Submit</button> :
                                <button style={{ border: "none", outline: "none" }}
                                    className="block w-full max-w-[260px] text-white rounded-2xl feddup-grey-bg text-base font-medium mx-auto py-2 px-6 mb-4"
                                    disabled="true">Submit</button>
                            }
                            {(title.length != 0) && (suggestion.length != 0) ?
                                "" :
                                <div className="feddup__popup__feedback__validation__div" style={{ zIndex: "1003" }}>
                                    <p className="feddup__feddup__feddup__text-xs feddup__text-gray-500" style={{ color: 'grey' }}>Please add title and description</p>
                                </div>
                            }
                        </div>

                    </>
            }
        </>
    )
}


function CheckboxQuestion({ question, handleFormData }) {
    const [arr, setArr] = useState(question?.ans || [])
    const handleCheck = (id) => {
        if (arr.includes(id)) {
            let newArr = [...arr];
            let i = newArr.findIndex((item) => item == id);
            if (i > -1) {
                newArr.splice(i, 1);
                setArr(newArr)
            }
        }
        else
            setArr((prev) => [...prev, id])
    }

    useEffect(() => {
        handleFormData(question.id, arr)
    }, [arr])
    return (<>
        <h1 className="feddup__popup_text-lg">{question.question}</h1>
        {question.options.map(option => <div className="py-1">
            <input type="checkbox" role="checkbox" name={option.option} id={question.id} checked={arr.includes(option.id)} onChange={() => handleCheck(option.id)} value={option.id} className="feddup__popup__questionnaire__checkbox__input" />
            <label htmlFor={option.id} className="feddup__text-gray-500 text-base">{option.option}</label>
        </div>)}
    </>)
}



export const useDebounce = (value, delay = 300) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value)
        }, delay);
        return () => {
            clearTimeout(handler);
        }
    }, [value, delay]);

    return debouncedValue;
}
